import React, { useContext } from "react";
import { ItemList } from "./ItemList";
import { CartContext } from "../context/CartContext";

const ItemListContainer = () => {
  window.scrollTo(0, 0);
  const cartContext = useContext(CartContext);
  const { items } = cartContext;

  return (
    <>
      <div className="items row marginTienda">
        <ItemList items={items} />
      </div>
      -
    </>
  );
};

export default ItemListContainer;
