import React from "react";

const Waterfalls = () => {
  return (
    <>
      <section id="losPaisajesDelFrio">
        <h2>Waterfalls</h2>
        <div className="container-fluid overflow-hidden">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="cajaImg2">
                <img
                  src="/waterfalls/waterfalls (3).jpg"
                  className="img-fluid"
                  alt="Waterfalls"
                />
                <p className="text-center"></p>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="cajaImg2">
                <img
                  src="/waterfalls/waterfalls (5).jpg"
                  className="img-fluid"
                  alt="Waterfalls"
                />
                <p className="text-center"></p>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="cajaImg2">
                <img
                  src="/waterfalls/waterfalls (7).jpg"
                  className="img-fluid"
                  alt="Waterfalls"
                />
                <p className="text-center"></p>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="cajaImg2">
                <img
                  src="/waterfalls/waterfalls (1).jpg"
                  className="img-fluid"
                  alt="Waterfalls"
                />
                <p className="text-center"></p>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="cajaImg2">
                <img
                  src="/waterfalls/waterfalls (2).jpg"
                  className="img-fluid"
                  alt="Waterfalls"
                />
                <p className="text-center"></p>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="cajaImg2">
                <img
                  src="/waterfalls/waterfalls (6).jpg"
                  className="img-fluid"
                  alt="Waterfalls"
                />
                <p className="text-center"></p>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="cajaImg2">
                <img
                  src="/waterfalls/waterfalls (8).jpg"
                  className="img-fluid"
                  alt="Waterfalls"
                />
                <p className="text-center"></p>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="cajaImg2">
                <img
                  src="/waterfalls/waterfalls (4).jpg"
                  className="img-fluid"
                  alt="Waterfalls"
                />
                <p className="text-center"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Waterfalls;
