import React from "react";
import Carousel from "../Carousel";

const CuevasDeInvierno = () => {
  const settings1 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "easy-in",
    responsive: [
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  const settings2 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "easy-in",
    responsive: [
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  const settings3 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "easy-in",
    responsive: [
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  const settings4 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "easy-in",
    responsive: [
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  const settings5 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "easy-in",
    responsive: [
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  const settings6 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "easy-in",
    responsive: [
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  const settings7 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "easy-in",
    responsive: [
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  const settings8 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "easy-in",
    responsive: [
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  return (
    <>
      <section id="parquesNacionales" className="cuevaza">
        <h2>Cuevas de invierno</h2>
        <div className="container-fluid cuevas ">
          <div className="row">
            <figure className="img-container figure col-md-4 paradas col-6">
              <div className="figure-img img-fluid rounded mx-auto d-block">
                <Carousel settings={settings1}>
                  <div className=" ">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (28).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (29).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (30).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (31).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                </Carousel>
              </div>
              <div className="textoSobre">
                Nido de Boyero negro, cerámica, corteza de eucaliptus y
                pigmentos naturales <br />
                2023
              </div>
            </figure>
            <figure className="img-container figure col-md-4 paradas col-6">
              <div className="figure-img img-fluid rounded mx-auto d-block">
                <Carousel settings={settings2}>
                  <div className=" ">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (32).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (33).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (34).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (35).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (36).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (37).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                </Carousel>
              </div>
              <div className=" textoSobre">
                Cerámica y ramitas del monte entrerriano <br />
                2023
              </div>
            </figure>
            <figure className="img-container figure col-md-4 paradas col-6">
              <div className="figure-img img-fluid rounded mx-auto d-block">
                <Carousel settings={settings3}>
                  <div className=" ">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (1).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (2).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (3).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                </Carousel>
              </div>
              <div className=" textoSobre">
                Cerámica, mica y ramitas del monte entrerriano <br />
                2023
              </div>
            </figure>
            <figure className="img-container figure col-md-4 paradas col-6">
              <div className="figure-img img-fluid rounded mx-auto d-block">
                <Carousel settings={settings4}>
                  <div className=" ">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (19).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (20).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (21).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (22).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (23).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (24).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                </Carousel>
              </div>
              <div className=" textoSobre">
                Cerámica, biomaterial y ramitas del monte entrerriano <br />
                2023
              </div>
            </figure>
            <figure className="img-container figure col-md-4 paradas col-6">
              <div className="figure-img img-fluid rounded mx-auto d-block">
                <Carousel settings={settings5}>
                  <div className=" ">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (16).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (17).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (18).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                </Carousel>
              </div>
              <div className=" textoSobre">
                Cerámica, mica y biomaterial <br />
                2023
              </div>
            </figure>
            <figure className="img-container figure col-md-4 paradas col-6">
              <div className="figure-img img-fluid rounded mx-auto d-block">
                <Carousel settings={settings6}>
                  <div className=" ">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (10).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (11).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (12).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (13).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (14).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (15).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                </Carousel>
              </div>
              <div className=" textoSobre">
                Cerámica, mica, biomaterial y ramitas del monte entrerriano{" "}
                <br />
                2023
              </div>
            </figure>
            <div className="col-12 col-md-6 pCuevas">
              <figure className="img-container figure col-12">
                <div className="figure-img img-fluid rounded mx-auto d-block">
                  <Carousel settings={settings7}>
                    <div className=" ">
                      <img
                        src="/cuevasDeInvierno/CuevasDeInvierno (4).jpg"
                        className="d-block mx-auto img-fluid"
                        alt="..."
                      />
                    </div>
                    <div className="">
                      <img
                        src="/cuevasDeInvierno/CuevasDeInvierno (5).jpg"
                        className="d-block mx-auto img-fluid"
                        alt="..."
                      />
                    </div>
                    <div className="">
                      <img
                        src="/cuevasDeInvierno/CuevasDeInvierno (6).jpg"
                        className="d-block mx-auto img-fluid"
                        alt="..."
                      />
                    </div>
                    <div className="">
                      <img
                        src="/cuevasDeInvierno/CuevasDeInvierno (7).jpg"
                        className="d-block mx-auto img-fluid"
                        alt="..."
                      />
                    </div>
                    <div className="">
                      <img
                        src="/cuevasDeInvierno/CuevasDeInvierno (8).jpg"
                        className="d-block mx-auto img-fluid"
                        alt="..."
                      />
                    </div>
                    <div className="">
                      <img
                        src="/cuevasDeInvierno/CuevasDeInvierno (9).jpg"
                        className="d-block mx-auto img-fluid"
                        alt="..."
                      />
                    </div>
                  </Carousel>
                </div>
                <div className=" textoSobre">
                  Gasa de algodón, tintes naturales, cerámica y fieltro <br />
                  2023
                </div>
              </figure>
              <figure className="img-container figure col-12">
                <div className="figure-img img-fluid rounded mx-auto d-block">
                  <Carousel settings={settings8}>
                    <div className=" ">
                      <img
                        src="/cuevasDeInvierno/CuevasDeInvierno (25).jpg"
                        className="d-block mx-auto img-fluid"
                        alt="..."
                      />
                    </div>
                    <div className="">
                      <img
                        src="/cuevasDeInvierno/CuevasDeInvierno (26).jpg"
                        className="d-block mx-auto img-fluid"
                        alt="..."
                      />
                    </div>
                    <div className="">
                      <img
                        src="/cuevasDeInvierno/CuevasDeInvierno (27).jpg"
                        className="d-block mx-auto img-fluid"
                        alt="..."
                      />
                    </div>
                  </Carousel>
                </div>

                <div className=" textoSobre">
                  Cerámica y juncos del Rio Paraná <br />
                  2023
                </div>
              </figure>
            </div>

            <figure className="img-container figure col-md-6 ">
              <div className="figure-img img-fluid rounded mx-auto d-block">
                <Carousel settings={settings8}>
                  <div className=" ">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (41).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className=" ">
                    <img
                      src="/cuevasDeInvierno/CuevasDeInvierno (40).jpg"
                      className="d-block mx-auto img-fluid"
                      alt="..."
                    />
                  </div>
                </Carousel>
              </div>

              <div className=" textoSobre">
                Tintas botánicas sobre pared. <br />
                "Poética de lo efímero" - Almacén arte contemporáneo. <br />
                2024
              </div>
            </figure>
          </div>
        </div>
      </section>
    </>
  );
};

export default CuevasDeInvierno;
