import React from "react";
import Carousel from "./Carousel";
import BtnCompra from "./BtnCompra";

export const ItemDetail = ({ itemDetail }) => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  return (
    <>
      <div className="row">
        <div
          className="cajilla pe-md-5 col-12 col-md-6  mt-md-3"
          key={itemDetail.id}
        >
          <div className="pe-md-5">
            <Carousel settings={settings}>
              {itemDetail.imagenes.map((img) => (
                <div key={itemDetail.id}>
                  <img
                    src={img.url}
                    alt={itemDetail.titulo}
                    className="img-fluid"
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        <div className="cajilla datos mt-3 col-12 col-md-6">
          <div>
            <h1>{itemDetail.titulo}</h1>

            <p className="text-start">${itemDetail.precio.toLocaleString()} </p>
          </div>
          <div
            className="mt-3 htmlEn"
            dangerouslySetInnerHTML={{ __html: itemDetail.descripcion }}
          />
          <div className="mt-3">
            <h4>
              <i className="bi bi-bank me-2"></i>Transferencia / Mercado Pago{" "}
            </h4>
            <h4>
              <i className="bi bi-truck me-2"></i>Envíos a todo el país
            </h4>
            <h4>
              <i className="bi bi-heart-fill me-2"></i>Piezas únicas hechas a
              mano
            </h4>
            Cart
          </div>
          <BtnCompra producto={itemDetail} />
        </div>
      </div>
    </>
  );
};
