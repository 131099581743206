import React from "react";

const Loader = () => {
  return (
    <div className="contenido">
      <div className="spinner"></div>
      <i className="bi bi-snow2"></i>
    </div>
  );
};

export default Loader;
