import React from "react";
import { Link } from "react-router-dom";
import Carousel from "./Carousel";

const Proyectos = (props) => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  const proyectosData = [
    // {
    //   titulo: "Un reflejo azul de invierno",
    //   imagen: "/unReflejoAzulDeInvierno/un_reflejo_azul_de_invierno_1.JPG",
    //   url: "/proyecto/un-reflejo-azul-de-invierno", // Agregado "/"
    // },
    {
      titulo: "Parques Nacionales",
      imagen: "/parquesNacionales/un_parque_nacional_en_alaska.JPG",
      url: "/proyecto/parques-nacionales", // Agregado "/"
    },
    {
      titulo: "Colour me in",
      imagen: "/colourMeIn/colorful_natural_dyes.JPG",
      url: "/proyecto/colour-me-in", // Agregado "/"
    },
    {
      titulo: "Suelos del sur",
      imagen: "/suelosDelSur/suelos_del_sur_IV.JPG",
      url: "/proyecto/suelos-del-sur", // Agregado "/"
    },
    {
      titulo: "Los paisajes del frío",
      imagen: "/losPaisajesDelFrio/blue_Beagle.jpg",
      url: "/proyecto/los-paisajes-del-frio", // Agregado "/"
    },
    {
      titulo: "Foraging for pigments",
      imagen: "/foragingforPigments/handmade_natural_watercolors.JPG",
      url: "/proyecto/foraging-for-pigments", // Agregado "/"
    },
    {
      titulo: "Invernal",
      imagen: "/invernal/foggy_and_trees_III.jpg",
      url: "/proyecto/invernal", // Agregado "/"
    },
    {
      titulo: "Naturaleza azul",
      imagen: "/blueLove/invierno_con_cuarzos.JPG",
      url: "/proyecto/naturaleza-azul", // Agregado "/"
    },
    {
      titulo: "Hornear en pozo",
      imagen: "/hornearEnPozo/hornear_en_pozo_III.JPG",
      url: "/proyecto/hornear-en-pozo", // Agregado "/"
    },
    {
      titulo: "Revelar con plantas",
      imagen: "/reveladosOrganicos/revelada_con_oregano.jpg",
      url: "/proyecto/revelar-con-plantas", // Agregado "/"
    },
    {
      titulo: "Los lugares donde te amé",
      imagen: "/losLugaresDondeTeAme/los_lugares_donde_te_ame_VIII.jpg",
      url: "/proyecto/los-lugares-donde-te-ame", // Agregado "/"
    },
    {
      titulo: "Ser silvestre",
      imagen: "/serSilvestre/serSilvestre%20(4).jpg",
      url: "/proyecto/ser-silvestre", // Agregado "/"
    },
    {
      titulo: "Cuevas de invierno",
      imagen: "/cuevasDeInvierno/CuevasDeInvierno%20(20).jpg",
      url: "/proyecto/cuevas-de-invierno", // Agregado "/"
    },
    {
      titulo: "PN Lago Puelo",
      imagen: "/PNLagoPuelo/pnLagoPuelo%20(23).jpg",
      url: "/proyecto/pn-lago-puelo", // Agregado "/"
    },
    {
      titulo: "Waterfalls",
      imagen: "/waterfalls/waterfalls (1).jpg",
      url: "/proyecto/waterfalls", // Agregado "/"
    },
  ];

  return (
    <>
      <section id="proyectos">
        <h2>Proyectos</h2>
        <div className="container-fluid m-0 ">
          <div className="imgDesktop col-12 ">
            <Carousel settings={settings}>
              {proyectosData.map((proyecto, index) => (
                <div className="slick-slide" key={index}>
                  <div className="cajaCar">
                    <img
                      src={proyecto.imagen}
                      alt={proyecto.titulo}
                      className="img-fluid centered-image"
                    />
                    <div className="pieContacto">
                      <Link to={proyecto.url}>{proyecto.titulo} !</Link>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>

          <Link
            to="#bio"
            className=""
            onClick={() => props.handleScrollTo("#bio")}
          >
            <div className="flecha">¡</div>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Proyectos;
