import React from "react";
import { Link } from "react-router-dom";

const Bio = (props) => {
  return (
    <>
      <section id="bio">
        <h2>Bio</h2>
        <div className="cajaBio">
          <div>
            <img
              className="imgRedonda floatLeft wow animate__animated animate__flipInY animate__delay-1s"
              src="/index/bio.png"
              alt="Arte naturalista. Obras y procesos."
            />
          </div>
          <p>
            Me gradué de Licenciada en Bellas Artes en la UNR y me especialicé
            en Estudios Irlandeses en la USAL.
          </p>
          <p>
            Dialogo entre arte y naturaleza. Todo mi trabajo y mi vida está
            atravesado por los materiales y los tiempos de la naturaleza y
            pensado también para generar el menor impacto negativo posible en el
            ambiente.
          </p>
          <p>
            Mi producción se desplaza entre la cerámica, los tintes naturales,
            la pintura y la elaboración de acuarelas y tintas botanicas y
            minerales, los biomateriales, el fieltro, la costura, el bordado, la
            fabricación de papel, la encuadernación, la fotografia analógica y
            experimental, la restauración y hasta la preparación de mis propios
            cosméticos.
          </p>
          <p>
            Actualmente soy adscripta a la cátedra de Pintura III en la UNR, doy
            clases de cerámica en mi taller y soy parte de Revelar con plantas.{" "}
          </p>
        </div>

        <Link
          to="#statement"
          className=""
          onClick={() => props.handleScrollTo("#statement")}
        >
          <div className="flecha">¡</div>
        </Link>
      </section>
    </>
  );
};

export default Bio;
