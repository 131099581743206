import React from "react";

import Carousel from "../Carousel";

const SerSilvestre = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "easy-in",
    responsive: [
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  const settings2 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "easy-in",
    responsive: [
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  const settings3 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "easy-in",
    responsive: [
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  const settings4 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "easy-in",
    responsive: [
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };

  return (
    <>
      <section id="unReflejoAzulDeInvierno">
        <h2 className="">Ser silvestre</h2>

        <div className="container-fluid mx-0 px-0">
          <div className="row">
            <div className="largo2 row">
              <div className="largo">
                <div className="imgDesktop">
                  <Carousel settings={settings2}>
                    <img
                      src="/serSilvestre/serSilvestre (7).jpg"
                      className="img-fluid"
                      alt="..."
                    />
                    <img
                      src="/serSilvestre/serSilvestre (1).jpg"
                      className="img-fluid"
                      alt="..."
                    />
                    <img
                      src="/serSilvestre/serSilvestre (2).jpg"
                      className="img-fluid"
                      alt="..."
                    />
                    <img
                      src="/serSilvestre/serSilvestre (3).jpg"
                      className="img-fluid"
                      alt="..."
                    />
                    <img
                      src="/serSilvestre/serSilvestre (4).jpg"
                      className="img-fluid"
                      alt="..."
                    />
                    <img
                      src="/serSilvestre/serSilvestre (5).jpg"
                      className="img-fluid"
                      alt="..."
                    />
                    <img
                      src="/serSilvestre/serSilvestre (6).jpg"
                      className="img-fluid"
                      alt="..."
                    />
                    <img
                      src="/serSilvestre/serSilvestre (8).jpg"
                      className="img-fluid"
                      alt="..."
                    />
                    <img
                      src="/serSilvestre/serSilvestre (9).jpg"
                      className="img-fluid"
                      alt="..."
                    />
                  </Carousel>
                </div>
              </div>
              <div className="ancho">
                <div className="">
                  <div className="imgDesktop">
                    <Carousel settings={settings3}>
                      <img
                        src="/serSilvestre/paz_secundini_wild_heart_wild_stone_4.JPG"
                        className="img-fluid"
                        alt="..."
                      />
                      <img
                        src="/serSilvestre/paz_secundini_wild_heart_wild_stone_1.jpg"
                        className="img-fluid"
                        alt="..."
                      />
                      <img
                        src="/serSilvestre/paz_secundini_wild_heart_wild_stone_2.JPG"
                        className="img-fluid"
                        alt="..."
                      />
                      <img
                        src="/serSilvestre/paz_secundini_wild_heart_wild_stone_3.JPG"
                        className="img-fluid"
                        alt="..."
                      />

                      <img
                        src="/serSilvestre/paz_secundini_wild_heart_wild_stone_5.JPG"
                        className="img-fluid"
                        alt="..."
                      />
                      <img
                        src="/serSilvestre/paz_secundini_wild_heart_wild_stone_6.JPG"
                        className="img-fluid"
                        alt="..."
                      />
                      <img
                        src="/serSilvestre/paz_secundini_wild_heart_wild_stone_7.JPG"
                        className="img-fluid"
                        alt="..."
                      />

                      <img
                        src="/serSilvestre/paz_secundini_wild_heart_wild_stone_8.JPG"
                        className="img-fluid"
                        alt="..."
                      />

                      <img
                        src="/serSilvestre/paz_secundini_wild_heart_wild_stone_9.JPG"
                        className="img-fluid"
                        alt="..."
                      />
                    </Carousel>
                  </div>
                </div>
                <div className="">
                  <div className="imgDesktop">
                    <Carousel settings={settings}>
                      <img
                        src="/serSilvestre/paz_secundini_ser_silvestre_5.jpg"
                        className="img-fluid"
                        alt="..."
                      />
                      <img
                        src="/serSilvestre/paz_secundini_ser_silvestre_6.jpg"
                        className="img-fluid"
                        alt="..."
                      />
                      <img
                        src="/serSilvestre/paz_secundini_ser_silvestre_4.jpg"
                        className="img-fluid"
                        alt="..."
                      />

                      <img
                        src="/serSilvestre/paz_secundini_ser_silvestre_1.jpg"
                        className="img-fluid"
                        alt="..."
                      />
                      <img
                        src="/serSilvestre/paz_secundini_ser_silvestre_2.jpg"
                        className="img-fluid"
                        alt="..."
                      />
                      <img
                        src="/serSilvestre/paz_secundini_ser_silvestre_haiku.JPG"
                        className="img-fluid"
                        alt="..."
                      />
                      {/* <img
                        src="/serSilvestre/ser_silvestre_caracol.jpg"
                        className="img-fluid"
                        alt="..."
                      /> */}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>

            <div className="ancho2">
              <div className="">
                <div className="imgDesktop">
                  <Carousel settings={settings4}>
                    <img
                      src="/serSilvestre/paz_secundini_lo_sesible_lo_que_me_sostiene_1.JPG"
                      className="img-fluid"
                      alt="..."
                    />
                    <img
                      src="/serSilvestre/paz_secundini_lo_sesible_lo_que_me_sostiene_2.JPG"
                      className="img-fluid"
                      alt="..."
                    />
                    <img
                      src="/serSilvestre/paz_secundini_lo_sesible_lo_que_me_sostiene_3.JPG"
                      className="img-fluid"
                      alt="..."
                    />
                    <img
                      src="/serSilvestre/paz_secundini_lo_sesible_lo_que_me_sostiene_4.JPG"
                      className="img-fluid"
                      alt="..."
                    />
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SerSilvestre;
