import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import NaturalezaAzul from "./naturaleza-azul";
import ColourMeIn from "./colour-me-in";
import CuevasDeInvierno from "./cuevas-de-invierno";
import ForagingForPigments from "./foraging-for-pigments";
import HornearEnPozo from "./hornear-en-pozo";
import Invernal from "./invernal";
import LosLugaresDondeTeAme from "./los-lugares-donde-te-ame";
import LosPaisajesDelFrio from "./los-paisajes-del-frio";
import PNLagoPuelo from "./pn-lago-puelo";
import ParquesNacionales from "./parques-nacionales";
import ReveladosOrganicos from "./revelados-organicos";
import SerSilvestre from "./ser-silvestre";
import SuelosDelSur from "./suelos-del-sur";
// import UnReflejoAzulDeInvierno from "./un-reflejo-azul-de-invierno";
import Waterfalls from "./waterfalls";

const componentMap = {
  "naturaleza-azul": <NaturalezaAzul />,
  "colour-me-in": <ColourMeIn />,
  "cuevas-de-invierno": <CuevasDeInvierno />,
  "foraging-for-pigments": <ForagingForPigments />,
  "hornear-en-pozo": <HornearEnPozo />,
  invernal: <Invernal />,
  "los-lugares-donde-te-ame": <LosLugaresDondeTeAme />,
  "los-paisajes-del-frio": <LosPaisajesDelFrio />,
  "pn-lago-puelo": <PNLagoPuelo />,
  "parques-nacionales": <ParquesNacionales />,
  "revelar-con-plantas": <ReveladosOrganicos />,
  "ser-silvestre": <SerSilvestre />,
  "suelos-del-sur": <SuelosDelSur />,
  // "un-reflejo-azul-de-invierno": <UnReflejoAzulDeInvierno />,
  waterfalls: <Waterfalls />,
};
const Proyecto = () => {
  window.scrollTo(0, 0);

  const { urlProyecto } = useParams();
  const componentToShow = componentMap[urlProyecto] || null;
  const proyectosData = [
    // {
    //   titulo: "Un reflejo azul de invierno",
    //   imagen: "/unReflejoAzulDeInvierno/un_reflejo_azul_de_invierno_1.JPG",
    //   url: "/proyecto/un-reflejo-azul-de-invierno", // Agregado "/"
    // },
    {
      titulo: "Parques Nacionales",
      imagen: "/parquesNacionales/un_parque_nacional_en_alaska.JPG",
      url: "/proyecto/parques-nacionales", // Agregado "/"
    },
    {
      titulo: "Colour me in",
      imagen: "/colourMeIn/colorful_natural_dyes.JPG",
      url: "/proyecto/colour-me-in", // Agregado "/"
    },
    {
      titulo: "Suelos del sur",
      imagen: "/suelosDelSur/suelos_del_sur_IV.JPG",
      url: "/proyecto/suelos-del-sur", // Agregado "/"
    },
    {
      titulo: "Los paisajes del frío",
      imagen: "/losPaisajesDelFrio/blue_beagle.jpg",
      url: "/proyecto/los-paisajes-del-frio", // Agregado "/"
    },
    {
      titulo: "Foraging for pigments",
      imagen: "/foragingforPigments/handmade_natural_watercolors.JPG",
      url: "/proyecto/foraging-for-pigments", // Agregado "/"
    },
    {
      titulo: "Invernal",
      imagen: "/invernal/foggy_and_trees_III.jpg",
      url: "/proyecto/invernal", // Agregado "/"
    },
    {
      titulo: "Naturelaza azul",
      imagen: "/blueLove/invierno_con_cuarzos.JPG",
      url: "/proyecto/naturaleza-azul", // Agregado "/"
    },
    {
      titulo: "Hornear en pozo",
      imagen: "/hornearEnPozo/hornear_en_pozo_III.JPG",
      url: "/proyecto/hornear-en-pozo", // Agregado "/"
    },
    {
      titulo: "Revelar con plantas",
      imagen: "/reveladosOrganicos/revelada_con_oregano.jpg",
      url: "/proyecto/revelar-con-plantas", // Agregado "/"
    },
    {
      titulo: "Los lugares donde te amé",
      imagen: "/losLugaresDondeTeAme/los_lugares_donde_te_ame_VIII.jpg",
      url: "/proyecto/los-lugares-donde-te-ame", // Agregado "/"
    },
    {
      titulo: "Ser silvestre",
      imagen: "/serSilvestre/serSilvestre%20(4).jpg",
      url: "/proyecto/ser-silvestre", // Agregado "/"
    },
    {
      titulo: "Cuevas de invierno",
      imagen: "/cuevasDeInvierno/CuevasDeInvierno%20(20).jpg",
      url: "/proyecto/cuevas-de-invierno", // Agregado "/"
    },
    {
      titulo: "PN Lago Puelo",
      imagen: "/PNLagoPuelo/pnLagoPuelo%20(23).jpg",
      url: "/proyecto/pn-lago-puelo", // Agregado "/"
    },
    {
      titulo: "Waterfalls",
      imagen: "/waterfalls/waterfalls (1).jpg",
      url: "/proyecto/waterfalls", // Agregado "/"
    },
  ];
  // Busca el índice del proyecto actual en proyectosData
  const currentIndex = proyectosData.findIndex(
    (proyecto) => proyecto.url === `/proyecto/${urlProyecto}`
  );

  // Obtiene el proyecto anterior y el proyecto posterior
  let proyectoAnterior = null;
  let proyectoPosterior = null;

  if (currentIndex >= 0) {
    proyectoAnterior =
      currentIndex > 0
        ? proyectosData[currentIndex - 1]
        : proyectosData[proyectosData.length - 1]; // Si no existe el anterior, muestra el último proyecto
    proyectoPosterior =
      currentIndex < proyectosData.length - 1
        ? proyectosData[currentIndex + 1]
        : proyectosData[0]; // Si no existe el posterior, muestra el primer proyecto
  }

  console.log(proyectoAnterior);
  console.log(proyectoPosterior);
  return (
    <div className="padProyectos">
      {componentToShow}
      <footer>
        {proyectoAnterior && (
          <div>
            <Link to={`${proyectoAnterior.url}`}>;</Link>
          </div>
        )}
        {proyectoPosterior && (
          <div>
            <Link to={`${proyectoPosterior.url}`}>
              {proyectoPosterior.titulo} !
            </Link>
          </div>
        )}
      </footer>
    </div>
  );
};

export default Proyecto;
