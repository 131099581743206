import React from "react";
import Player from "react-player";

const ColourMeIn = () => {
  return (
    <>
      <section id="colourMeIn">
        <h2>Colour me in</h2>
        <div className="container-fluid overflow-hidden">
          <div className="row m-0 p-0">
            <img
              className="col-6 p-0 m-0"
              src="/colourMeIn/acorn_dye.JPG"
              alt="cuchara en compotera con tinte de bellotas"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Tinte de bellotas"
            />
            <img
              className="col-6 p-0 m-0"
              src="/colourMeIn/scarfs_with_natural_dyes.JPG"
              alt="cinco pañuelos doblados con diferentes tintes naturales"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Tintes naturales"
            />
          </div>
          <div className="row m-0 p-0">
            <div className="col-12 p-0 m-0">
              <Player
                url="/colourMeIn/ecoprinting_on_paper.mp4"
                muted={true}
                playing={true}
                loop={true}
                width={"100vw"}
                height={"auto"}
                style={{ marginBottom: -10, padding: 0 }}
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Ecoprint sobre papel"
              />
            </div>
          </div>
          <div className="row m-0 p-0">
            <img
              className="col-4 p-0 m-0"
              src="/colourMeIn/biomaterial.JPG"
              alt="Biomaterial sobre hoja"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Biomaterial"
            />
            <img
              className="col-4 p-0 m-0"
              src="/colourMeIn/ecoprint_III.JPG"
              alt="Ecoprint amarillo y rojo sobre tela"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Ecoprint"
            />
            <img
              className="col-4 p-0 m-0"
              src="/colourMeIn/ecoprint_II.JPG"
              alt="Ecoprint rojo sobre tela"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Ecoprint"
            />
          </div>
          <div className="row m-0 p-0">
            <img
              className="col-12 p-0 m-0"
              src="/colourMeIn/colorful_natural_dyes.JPG"
              alt="Tres telas colgadas teñindas de amarillo y otros colores"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Tintes naturales y ecoprint"
            />
          </div>
          <div className="row m-0 p-0">
            <img
              className="col-6 p-0 m-0"
              src="/colourMeIn/ecoprinted_dress.JPG"
              alt="Vestido con ecoprint colgado de un arbol"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Ecoprint"
            />
            <img
              className="col-6 p-0 m-0"
              src="/colourMeIn/ecoprint_I.JPG"
              alt="Tela con ecoprint colgada contra la pared"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Ecoprint"
            />
          </div>
          <div className="row m-0 p-0">
            <img
              className="col-12 p-0 m-0"
              src="/colourMeIn/blue_shibori.JPG"
              alt="Seis telas azules con shibori colgadas en una soga"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Shibori"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ColourMeIn;
