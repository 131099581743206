import React, { useState, useEffect } from "react";

const Invernal = () => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      // Verifica si el usuario ha llegado al final de la página
      if (scrollY + windowHeight + 65 >= documentHeight) {
        // Llegó al final, ahora esperamos 2 segundos antes de mostrar el texto

        // setTimeout(() => {
        setShowText(true);
        // }, 1500);
      }
    };

    // Agrega el evento scroll
    window.addEventListener("scroll", handleScroll);

    // Limpia el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section id="invernal">
        <h2>Invernal</h2>

        <div className="container-fluid m-0 p-0 overflow-hidden">
          <div className="row m-0 p-0">
            <img
              className=" col-3 p-0 m-0 img-fluid"
              src="/invernal/foggy_and_trees_I.jpg"
              alt="arboles blanco y negro"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Fotografía analógica en blanco y negro"
            />
            <img
              className=" col-3 p-0 m-0"
              src="/invernal/foggy_and_trees_II.jpg"
              alt="arboles blanco y negro"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Fotografía analógica en blanco y negro"
            />
            <img
              className=" col-6 p-0 m-0"
              src="/invernal/foggy_and_trees_III.jpg"
              alt="arboles blanco y negro"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Fotografía analógica en blanco y negro"
            />
          </div>
          <div className="row m-0 p-0">
            <img
              className=" col-4 p-0 m-0 img-fluid"
              src="/invernal/invernal_II.jpg"
              alt="frente de casa, blanco y negro"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Fotografía analógica en blanco y negro"
            />
            <img
              className=" col-4 p-0 m-0"
              src="/invernal/invernal_IV.jpg"
              alt="frente de casa, blanco y negro"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Fotografía analógica en blanco y negro"
            />
            <img
              className=" col-4 p-0 m-0"
              src="/invernal/invernal_V.jpg"
              alt="perro sentado en un banco en el parque"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Fotografía analógica en blanco y negro"
            />
          </div>
          <div className="row m-0 p-0">
            <img
              className=" col-3 p-0 m-0"
              src="/invernal/invernal_VII.jpg"
              alt="rosa"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Fotografía analógica en blanco y negro"
            />
            <img
              className=" col-6 p-0 m-0 img-fluid"
              src="/invernal/invernal_VIII.jpg"
              alt="escalera en un parque"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Fotografía analógica en blanco y negro"
            />
            <img
              className=" col-3 p-0 m-0"
              src="/invernal/invernal_VI.jpg"
              alt="fuente con estatua"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Fotografía analógica en blanco y negro"
            />
          </div>
          <div className="row m-0 p-0">
            <img
              className=" col-6 p-0 m-0 img-fluid"
              src="/invernal/wild_window.jpg"
              alt="ventana con enredadera"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Fotografía analógica en blanco y negro"
            />
            <img
              className=" col-6 p-0 m-0"
              src="/invernal/invernal_I.jpg"
              alt="puerta con enredadera"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Fotografía analógica en blanco y negro"
            />
          </div>
        </div>
        <div className="pt-3 pb-3">
          <p
            className={`text-center fs-2 p-0 m-0 fade-in ${
              showText ? "showText" : ""
            }`}
          >
            "Desear que siempre sea invierno"
          </p>
        </div>
      </section>{" "}
    </>
  );
};

export default Invernal;
