import { FormContacto } from "./FormContacto";

const Contacto = () => {
  return (
    <>
      <section id="contacto" className="overflow-hidden ">
        <div className="cajaH2">
          <h2> Contacto</h2>
        </div>
        <div className="transparencia">
          <div className="col-12 col-md-6 contactera">
            <FormContacto />
          </div>
          <div className="col-12 col-md-6 contactera">
            <div className="orden">
              <a
                title="instagram"
                href="https://www.instagram.com/jardin.de.invierno/"
                target="_blank"
                rel="noreferrer"
              >
                ! @jardin.de.invierno
              </a>

              <a
                title="email"
                href="mailto:mariapazsecundini@hotmail.com"
                target="_blank"
                rel="noreferrer"
              >
                ! mariapazsecundini@hotmail.com
              </a>

              <a
                title="mapa"
                href="https://www.google.com/maps/place/Rosario,+Santa+Fe/data=!4m2!3m1!1s0x95b6539335d7d75b:0xec4086e90258a557?sa=X&ved=2ahUKEwijs726yZH2AhX_yDgGHWJoAWoQ8gF6BAgHEAE"
                target="_blank"
                rel="noreferrer"
              >
                ! Rosario, Santa Fé, Argentina
              </a>

              <a title="cv" href="/curriculum-vitae">
                ! Curriculum Vitae
              </a>

              <a
                title="galeria"
                href="https://www.almacendearte.net/paz-secundini"
                target="_blank"
                rel="noreferrer"
              >
                ! Almacen.ArteContemporaneo
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contacto;
