import React, { useState, useEffect } from "react";

const NaturalezaAzul = () => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowText(true);
    }, 500);
  }, []);

  return (
    <>
      <section id="blueLove">
        <h2>Naturaleza azul</h2>
        <p
          className={`text-center fs-2 p-0 m-0 fade-in ${
            showText ? "showText" : ""
          }`}
        >
          "El azul siempre es mi preferido "
        </p>
        <div className="container-fluid overflow-hidden">
          <div className="row">
            <img
              className="pb-3 col-6 col-md-4 col-lg-3 img-fluid"
              src="/blueLove/botanica_invernal.JPG"
              alt="cianotipia"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Botánica invernal"
            />
            <img
              className="pb-3 col-6 col-md-4 col-lg-3"
              src="/blueLove/invierno_con_cuarzos.JPG"
              alt="cianotipia"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Invierno con cuarzos"
            />

            <img
              className="pb-3 col-6 col-md-4 col-lg-3"
              src="/blueLove/helecho_liquen_y_cuarzo_cristal.JPG"
              alt="cianotipia"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Helecho, liquen y cuarzo cristal"
            />
            <img
              className="pb-3 col-6 col-md-4 col-lg-3"
              src="/blueLove/glass_dome.JPG"
              alt="cianotipia"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Glass dome"
            />

            <img
              className="pb-3 col-6 col-md-8 col-lg-6"
              src="/blueLove/cianocromatografias.jpg"
              alt="cianotipia"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Cianocromatografía"
            />
            <img
              className="pb-3 col-6 col-md-8 col-lg-6"
              src="/blueLove/carpincho.JPG"
              alt="cianotipia"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Carpincho"
            />

            <img
              className="pb-3 col-6 col-md-4 col-lg-3"
              src="/blueLove/treboles_y_un_te.JPG"
              alt="cianotipia"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Tréboles y un té"
            />
            <img
              className="pb-3 col-6 col-md-4 col-lg-3"
              src="/blueLove/liquenes_del_monte.JPG"
              alt="cianotipia"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Líquenes del bosque"
            />
            <img
              className="pb-3 col-6 col-md-4 col-lg-3"
              src="/blueLove/guazuncho.JPG"
              alt="cianotipia"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Guazuncho"
            />
            <img
              className="pb-3 col-6 col-md-4 col-lg-3 pb-3"
              src="/blueLove/treboles.JPG"
              alt="cianotipia"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Tréboles"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default NaturalezaAzul;
