import React from "react";

const BtnCompra = ({ producto }) => {
  const handleSubmit = (event) => {
    event.preventDefault();

    const celu = window.innerWidth < 990 ? "api" : "web";
    const mensaje = `Me interesa este producto\n*${producto.titulo}*\n_$${producto.precio}_`;

    const envio = `Envíos a todo el país a cargo del comprador por medio de Correo Argentino\nEn Rosario cadetería o retiro en zona centro`;
    const pago = `Pago por transferencia al alias *jardin-de-invierno*`;

    const whatsapp = `*Hola Paz!* 🫖\n${mensaje}\n${envio}\n${pago}`;

    const linkCompra = `https://${celu}.whatsapp.com/send?phone=5493413779106&text=${encodeURIComponent(
      whatsapp
    )}`;

    window.open(linkCompra, "_blank");
  };

  return (
    <>
      <>
        <form className="form" action="" onSubmit={handleSubmit}>
          <button type="submit" className="boton btnWhat">
            Consultar <i className="bi bi-whatsapp"></i>
          </button>
        </form>
      </>
    </>
  );
};

export default BtnCompra;
