import React from "react";
import AccordionItem from "./AccordionItem";
const CV = (props) => {
  const data = [
    {
      title: "Ayudantías UNR",
      content: (
        <>
          <p>Adscripta</p>
          <p>Cátedra: Pintura III</p>
          <p>Profesora: Paula Grazzini</p>
          <p>Año: 2022 y continúa actualmente</p>
          <hr />
          <p>Ayudante alumna</p>
          <p>
            Cátedra Historia del Arte II: renacimiento, manierismo y barroco
          </p>
          <p>Profesora: Patricia Cartara</p>
          <p>Año: 2012</p>
          <hr />
        </>
      ),
    },
    {
      title: "Actividad profesional",
      content: (
        <>
          <p>
            2024 - Coordinadora de “Residencia de agua”, residencia artística
            itinerante, Argentina
          </p>
          <hr />
          <p>
            2023 - Coordinadora de “Ir al río”, residencia de fotografía
            experimental, Delta de Tigre, Buenos Aires, Argentina. Becada por el
            FNA
          </p>
          <hr />
          <p>
            2023 - Voluntaria en el Parque Nacional Lago Pueblo. Lago Pueblo,
            Chubut, durante el mes de febrero
          </p>
          <hr />
          <p>
            2022 - Curadora para Qué otras historias podemos contar? Diálogos
            entre arte y naturaleza. Organizado por Almacén.Arte Contemporáneo,
            agosto 2022, Argentina
          </p>
          <hr />
          <p>
            2022 - Coordinadora del taller de revelados orgánicos en Espacio
            Analógico, Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p>
            2021 y continúa actualmente - Artista para la galería Almacén. Arte
            Contemporáneo, San Nicolás de Los Arroyos, Buenos Aires, Argentina
          </p>
          <hr />
          <p>
            2019 y 2021 - Voluntaria en Open House Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p>
            2019 y continúa actualmente - Coordinadora de clases de cerámica en
            mi taller particular
          </p>
          <hr />
          <p>
            2016 - Miembro del grupo de restauración escultórica y
            arquitectónica “Claudio Blotta” a cargo del Arq. Gustavo Fernetti.
            Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p>
            2015 a 2018 - Profesora de arte reemplazante en escuelas públicas y
            privadas, nivel primario y secundario, Santa Fé, Argentina
          </p>
          <hr />
          <p>
            2014 y continúa actualmente - Dueña de Jardín de Invierno - Tienda
            de arte, producción propia
          </p>
          <hr />
          <p>
            2013 a 2014 - Profesora del taller de arte, para niños de 3 a 7
            años, en el Complejo Educativo La Ronda, Villa Mugueta, Santa Fe,
            Argentina
          </p>
          <hr />
          <p>
            2012 - Miembro del grupo de escenógrafas para “Experiencia Copi”,
            Centro de Expresiones Contemporáneas, Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p>
            2008 - Guía y guardia de sala en la muestra “León Ferrari
            Antológica”. Museo Municipal de Bellas Artes “Juan B. Castagnino”,
            Rosario, Santa Fe, Argentina, del 6 de mayo al 29 de junio
          </p>
          <hr />
          <p>
            2007 - Voluntaria en el taller de arte para niños organizado por la
            Secretaría de Extensión de la Facultad de Humanidades y Artes U.N.R,
            situado en Barrio Saladillo, Rosario, Argentina
          </p>
          <hr />
        </>
      ),
    },
    {
      title: "Cursos y seminarios académicos",
      content: (
        <>
          <p className="text-start">
            2024 - Taller "Territorios biomateriales" - Arte.Lab, Escuela de
            Artes Visuales UADER, Lic. Ana Laura Cantera
          </p>
          <hr />
          <p className="text-start">
            2023 - Conferencia “Arte y géneros. Introducción a prácticas
            artísticas contemporáneas con perspectivas feministas y
            sexodisidentes” - Lic. Paula Grazzini y Lic. Edgardo Donoso,
            Facultad de Humanidades y Artes UNR
          </p>
          <hr />
          <p className="text-start">
            2022 - Seminario internacional “Ireland at the international
            crossroads: history, culture and arts 1922 and beyond” - Catedras
            latinoamericanas de Estudios Irlandeses de la
            USAL/UNLPam/ULP/USP/UNAM
          </p>
          <hr />
          <p className="text-start">
            2021 - Seminario “Lengua, música y cultura de Irlanda” - Escuela de
            Lenguas Modernas USAL, Prof. Marcas O’Murchu
          </p>
          <hr />
          <p className="text-start">
            2019 - 4º Jornadas nacionales de investigación cerámica organizadas
            por IFIR Conicet, UTN San Nicolás y ATAC
          </p>
          <hr />
          <p className="text-start">
            2019 - Talleres interdisciplinarios de formación socioambiental -
            Anexo UNR, Re Sapiens y Secretaría de Extensión UNR
          </p>
          <hr />
          <p className="text-start">
            2018 - Taller de sellos en fotopolímero - Ciddai-Centro de
            Investigación Desarrollo y Difusión de Arte Impreso-UNR, Lic.
            Gabriela Rodi y Lic. Adrián Del Grosso
          </p>
          <hr />
          <p className="text-start">
            2015 - Curso de restauración arquitectónica y escultórica - Casa
            Tomada y Secretaría de Extensión UNR, Arquitecto Gustavo Fernetti
          </p>
          <hr />
          <p className="text-start">
            2013 - Seminario “Aproximación a los criterios de conservación y
            restauración de bienes culturales”, Facultad de Humanidades y Artes
            U.N.R, Lic. Estela Colomar, Lic. Ma. Amalia Evangelista, Lic. Diana
            Hamann y Arq. Ana María Sánchez
          </p>
          <hr />
        </>
      ),
    },
    {
      title: "Formación en cursos y talleres",
      content: (
        <>
          <p className="text-star">
            2024 - Taller "Explorando nuestros humedales" - Isla de Los
            Mástiles, Fundación El sol sale para todos
          </p>
          <hr />
          <p className="text-star">
            2023 - XIII Bienal de cerámica Villa Gesell. Seminarios “Esmaltes
            según la grilla de Ian Currie” y “Raku” - Ceramista Matias Fernandez
            Broda, Ceramistas Gabriel Tosi y César Velarde respectivamente.
          </p>
          <hr />
          <p className="text-star">
            2023 - Taller de raku - Cerámica Trashumante, Ceramista Sebastián
            Mendiburu
          </p>
          <hr />
          <p className="text-star">
            2023 - Introducción a la Administración de Parques Nacionales -
            Centro de Capacitación en Areas Protegidas de la Administración de
            Parques Nacionales. Profesores Pablo Cansanello, Estefania Demarchi,
            Gloria Grinstein, Paula Ghio
          </p>
          <hr />
          <p className="text-star">
            2022 - Taller de Pop Up - Museo Estevez, Artista Magalí Asigliano
          </p>
          <hr />
          <p className="text-star">
            2022 - Clínica de proyectos - Almacén. Arte contemporáneo, Artista y
            curador Leonardo Meyer
          </p>
          <hr />
          <p className="text-star">
            2022 - Seminario “Rocas: una palabra, muchas preguntas” - Mapa
            Geotour, Geóloga Marina Lema
          </p>
          <hr />
          <p className="text-start">
            2022 - Taller de fotografía sin cámara - Espacio Analógico,
            Fotógrafo Daniel Aimé
          </p>
          <hr />
          <p className="text-start">
            2021 - Taller de cianotipia - Espacio Maravilla, Fotógrafo Daniel
            Aimé
          </p>
          <hr />
          <p className="text-start">
            2021 - Taller de kintsugi - Domestika, Ceramista Clara Graziolino
          </p>
          <hr />
          <p className="text-start">
            2020 - Taller de química alternativa para fotografía, procesos
            orgánicos de revelado y copiado - Imagen invertida, Fotógrafo Nahuel
            Souto
          </p>
          <hr />
          <p className="text-start">
            2020 - Taller de biomateriales - Particular, Diseñadora Carolina
            Etchevers
          </p>
          <hr />
          <p className="text-start">
            2020 - Curso de esmaltes cerámicos para baja temperatura - La
            Mandrágora taller, Ceramista Fabiana Sacnun
          </p>
          <hr />
          <p className="text-start">
            2020 - Taller de narrativas fotográficas “Algo para Alguien” -
            Espacio Maravilla, Fotógrafo Guido Bigiolli
          </p>
          <hr />
          <p className="text-start">
            2020 - Taller de elaboración de tintas botánicas artesanales para
            papel y tela - Particular, Diseñadora Industrial Luciana Marrone
          </p>
          <hr />
          <p className="text-start">
            2020 - Taller de extracción de pigmentos naturales laca y
            elaboración de acuarelas artesanales botánicas - Particular,
            Diseñadora Industrial Luciana Marrone
          </p>
          <hr />
          <p className="text-start">
            2019 - Clinica de alfarería - Sahuira Tienda, Ceramistas Victoria
            Drisaldi y Fernando López de Espacio Cerámica
          </p>
          <hr />
          <p className="text-start">
            2019 - Taller de revelado fotográfico de película color - Espacio
            Analógico, Fotógrafas Lucila Heinberg y Ximena Cosin
          </p>
          <hr />
          <p className="text-start">
            2019 - Taller de revelado fotográfico en blanco y negro de película
            y papel - Espacio Analógico, Fotógrafa Laura Basilico
          </p>
          <hr />
          <p className="text-start">
            2019 - Taller de extracción de aceites esenciales - La casita
            Rosario, Esteban Mansilla
          </p>
          <hr />
          <p className="text-start">
            2019 - Workshop herbal: creá tu blend - Suri Ecotienda, Tamara Fanta
            de Gran Jardín Hora del té
          </p>
          <hr />
          <p className="text-start">
            2019 - Taller de preparados con hierbas - Museo de la Ciudad de
            Rosario, Herborista Cecile Queau
          </p>
          <hr />
          <p className="text-start">
            2019 - Taller de fotografía estenopeica - Espacio Maravilla,
            Fotografas Virginia Mazza y Sonia Bossio
          </p>
          <hr />
          <p className="text-start">
            2018 - Taller de antotipias - Espacio Maravilla, Fotografo Juan
            Antonio Palacios
          </p>
          <hr />
          <p className="text-start">
            2018 - Seminario libre de fotografía de registro patrimonial -
            Escuela Superior de Museología de Rosario, Lic. Orlando Boeme,
            Museólogas Florencia Ratero y Lucia Delmastro
          </p>
          <hr />
          <p className="text-start">
            2018 - Taller de shampoo solido - Jacarandá, Terapista floral Melina
            Correa
          </p>
          <hr />
          <p className="text-start">
            2018 - Taller de jabón artesanal - Jacaranda, Terapista floral
            Melina Correa
          </p>
          <hr />
          <p className="text-start">
            2017 - Taller de cosmética natural nivel III - Jacarandá, Terapista
            floral Melina Correa
          </p>
          <hr />
          <p className="text-start">
            2017 - Taller de silbatos y ocarinas en cerámica - Particular,
            Ceramista Dante Alberro
          </p>
          <hr />
          <p className="text-start">
            2017 - Taller experimental de papel de fibra vegetal y reciclado -
            Espacio C, Marina Pellechiarino
          </p>
          <hr />
          <p className="text-start">
            2017 - Taller de plantas medicinales - Jacarandá, Terapista floral
            Melina Correa
          </p>
          <hr />
          <p className="text-start">
            2017 - Workshop de telas y papeles marmolados - Espacio C, Gervasio
            Monchietti
          </p>
          <hr />
          <p className="text-start">
            2017 - Taller de cosmética natural nivel II - Jacarandá, Terapista
            Floral Melina Correa
          </p>
          <hr />
          <p className="text-start">
            2016 - Workshop “Abecedario personal” - Sala Lavardén, Ilustradora
            Isol Misenta
          </p>
          <hr />
          <p className="text-start">
            2016 - Taller de cosmética natural nivel I - Jacarandá, Terapista
            Floral Melina Correa
          </p>
          <hr />
          <p className="text-start">
            2016 - Seminario de fieltro artesanal - Cápsula, Artista Visual
            Analia Paganini
          </p>
          <hr />
          <p className="text-start">
            2015 - XI Bienal de cerámica Villa Gesell. Seminarios “Decoración
            con técnica de mishima” y “Goma bicromatada directa” - Ceramista
            Maria del Carmen Dichiera y Fotógrafa Romina Gutierrez
            respectivamente
          </p>
          <hr />
          <p className="text-start">
            2015 - Taller de torno alfarero y técnicas para decorar cerámica -
            Arcillamenta, Ceramista Sonia Romano
          </p>
          <hr />
          <p className="text-start">
            2014 - Taller de conservación preventiva de cartas y otros
            documentos personales - Archivo de Entre Ríos, Archivistas Josefina
            Lescano y Gilda Panozzo
          </p>
          <hr />
          <p className="text-start">
            2014 - Seminario Ecoprint sobre algodón - BOU Espacio de arte,
            Diseñadora Industrial Luciana Marrone
          </p>
          <hr />
          <p className="text-start">
            2014 - Curso de costura - Escuela de enseñanza media Prof. Luis
            Ravena, Alicia Ballerini
          </p>
          <hr />
          <p className="text-start">
            2013 - Taller de pintura sobre porcelana - Dulce Encantos Porcelana,
            Artista Visual Patricia Rodriguez Alcalá
          </p>
          <hr />
          <p className="text-start">
            2013 - Curso “Diseño de exposiciones en museos” - Centro Cultural
            Parque España, Raúl D’Amelio
          </p>
          <hr />
          <p className="text-start">
            2013 - Taller de dibujo y técnica de acuarelas - Sahuira Rosario,
            Artista Visual Miguel Depetris
          </p>
          <hr />
          <p className="text-start">
            2012 - Seminario “Conservación preventiva en colecciones de arte
            contemporáneo” - Bou espacio de arte, Profesor/Conservador de museos
            Nicolás Charles y Conservadora de museos Belén Bredicce
          </p>
          <hr />
          <p className="text-start">
            2011 - Seminario “Restauración y Conservación del Patrimonio
            Edificado” - Museo Municipal de Arte Decorativo “Firma y Odilo
            Estevez”, Profesora y Restauradora Cristina Lancellotti
          </p>
          <hr />
          <p className="text-start">
            2011 - Seminario “Muebles de estilo” - Luzdía Rosario, Diseñadores
            Equipacionales Pablo Almada y Andrea Pedraza
          </p>
          <hr />
          <p className="text-start">
            2011 - Taller de construcción y restauración de mosaicos -
            particular, Ceramista y Mosaiquista Mariel Trill Guillot
          </p>
          <hr />
          <p className="text-start">
            2011 - Curso “Museos y Arquitectura” - Museo Histórico Provincial
            “Julio Marc”, Asoc. Amigos del Museo Histórico y CEDODAL Rosario
          </p>
          <hr />
          <p className="text-start">
            2011 - Seminario audiovisual “Un viaje de reconocimiento hacia el
            pensamiento incaico: El legado que los Incas nos dejaron” -
            particular, Antropóloga Graciela Dacunto
          </p>
          <hr />
          <p className="text-start">
            2010 - Seminario teórico-práctico “Arte terapia” - ICH Rosario,
            Psicologa Karina Mirich
          </p>
          <hr />
          <p className="text-start">
            2010 - Seminario teórico-práctico “Expresión de conflictos en los
            dibujos”- Centro de Desarrollos Psicológicos, Psicologa Beatriz
            Salto
          </p>
          <hr />
          <p className="text-start">
            2010 - Seminario teórico-práctico “Deconstruyendo la mente,
            desarrollando la creatividad” - ICH Rosario, Psicologa Karina Mirich
          </p>
          <hr />
          <p className="text-start">
            2010 - Taller de análisis y crítica de cine “La mujer como fantasma”
            - ISCV Rosario, Guionista y Crítico Emiliano Oviedo
          </p>
          <hr />
          <p className="text-start">
            2010 - Taller de encuadernación y reparación de libros - particular,
            Gervasio Monchietti
          </p>
          <hr />
          <p className="text-start">
            2010 - Curso de ilustración de cuentos infantiles - ISCV Rosario,
            Ilustradora Valeria Cis
          </p>
          <hr />
          <p className="text-start">
            2010 - Curso de restauración y conservación de objetos de cerámica,
            terracota, porcelana, vidrio, mármol y materiales afines -
            particular, Ceramista y Restauradora Claudia Floegel Muller
          </p>
          <hr />
          <p className="text-start">
            2008/2009 - Taller de construcción de objetos de cerámica -
            particular, Ceramista Constanza Caterina
          </p>
          <hr />
        </>
      ),
    },
    {
      title: "Residencias de arte",
      content: (
        <>
          <p className="text-star">
            2024 - Río, residencia artística en La Paz, Entre Ríos, Argentina.
            Recibí la beca de artista regional
          </p>
          <hr />
          <p className="text-star">
            2022 - Mudar, residencia artística en Cabo Polonio, Uruguay.
            Seleccionada junto a Laura Basilico y nuestro proyecto Revelados
            Orgánicos para investigar reveladores fotográficos con elementos de
            la naturaleza del entorno marino
          </p>
          <hr />
          <p className="text-star">
            2022 - SirveVerse, laboratorio intensivo de creación colectiva y
            transdisciplinar centrado en la problemática socioambiental y su
            relación con prácticas artísticas que problematizan el territorio.
            Sede Córdoba, trabajo en torno a la temática regeneración de bosque
            nativo
          </p>
          <hr />
          <p className="text-start">
            2019 - Campamento cerámico: construcción de ollas de barro de
            principio a fin - La Cacharra cerámica, Ceramista Verónica Córdoba
          </p>
          <hr />
        </>
      ),
    },
    {
      title: "Colaboraciones y publicaciones",
      content: (
        <>
          <p className="text-star">
            2024 - “El arte del paisaje irlandés del bog”. Suplemento Ideas N°12
            de la Universidad del Salvador, Argentina
          </p>
          <hr />
          <p className="text-star">
            2023 - Jacinto de agua común. Fotolibro del proyecto Revelados
            orgánicos becado por Plan Fomento de la Provincia de Santa Fe
          </p>
          <hr />
          <p className="text-star">
            2023 - Publicación de una fotografía experimental en el N° 9 de
            Aeonian Magazine
          </p>
          <hr />
          <p className="text-star">
            2022 - Cátálogo “Qué otras historias podemos contar?” Conversatorios
            entre arte y naturaleza. Almacén.Arte Contemporáneo, Argentina
          </p>
          <hr />
          <p className="text-star">
            2021 - Conversatorio “La obra de arte y la artesanía, tradición y
            contemporaneidad” en el marco de ArteCo, Argentina
          </p>
          <hr />
          <p className="text-star">
            2020 - Publicación online Nº 1 de Black River Journal donde
            participo con una fotografía de uno de mis biomateriales, Reino
            Unido
          </p>
          <hr />
          <p className="text-star">
            2017 - Nota sobre papel hecho a mano para la revista “No Serial
            Number” edición de invierno, Londres
          </p>
          <hr />
          <p className="text-star">
            2017 - “Anuario de la Asociación de dibujantes argentinos (ADA)”
            donde participé con la ilustración “Orilla”. Argentina
          </p>
          <hr />
          <p className="text-star">
            2016 - “Anuario de la Asociación de dibujantes argentinos (ADA)”
            donde participé con la ilustración “Dala horses”. Argentina
          </p>
          <hr />
          <p className="text-star">
            2015 - “Vivo Latinoamérica: Las mejores ilustraciones
            Latinoamericanas 2015” donde fui seleccionada para participar con la
            obra “Livingroom”. Universidad de Palermo, Argentina
          </p>
          <hr />
        </>
      ),
    },
    {
      title: "Participación en Salones",
      content: (
        <>
          <p className="text-star">
            2023 - 5° Premio Fundación Osde. Buenos Aires, Argentina
          </p>
          <hr />
          <p className="text-star">
            2023 - 60° Salón Provincial de Entre Ríos. Museo Provincial de
            Bellas Artes Pedro E. Martinez, Paraná, Argentina
          </p>
          <hr />
          <hr />
        </>
      ),
    },
    {
      title: "Exposiciones",
      content: (
        <>
          <p className="text-star">
            2024 - Poética de lo efímero, junto a Marité Gutierrez, Almacén Arte
            contemporáneo, San Nicolás de los Arroyos, Pcia. de Buenos Aires,
            Argentina
          </p>
          <hr />
          <p className="text-star">
            2023 - “Jacinto de agua común” del proyecto Revelados Orgánicos.
            Planta, Rafaela, Santa Fe, Argentina
          </p>
          <hr />
          <p className="text-star">
            2022 - “Herbario doméstico”del proyecto Revelados Orgánicos,. Centro
            Cultura Cine Lumiere, Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p className="text-star">
            2022 - “Una ramita que atraviesa el aire”,del proyecto Revelados
            Orgánicos. Museo de Ciencias Naturales Angel Gallardo, Rosario,
            Santa Fe, Argentina
          </p>
          <hr />

          <p className="text-start">
            2022 - “Libros textiles”, colectiva virtual internacional del
            Festival de Experiencias Textiles, 2022
          </p>
          <hr />
          <p className="text-start">
            2022 - “8° Encuentro de Arte Textil”, muestra colectiva en Umbral
            Arte, CABA, Argentina
          </p>
          <hr />
          <p className="text-start">
            2022 - “Un reflejo azul de invierno”, muestra individual, Galería
            Almacén.Arte Contemporáneo, del 13 al 17 de agosto, San Nicolás de
            los Arroyos, Pcia de Buenos Aires, Argentina
          </p>
          <hr />
          <p className="text-star">
            2022 - “Reflections”, muestra virtual de The Analog Forever
            Magazine, EE.UU
          </p>
          <hr />
          <p className="text-star">
            2021 - ArteCo, Feria de arte del NEA y Paraguay, artista de Galería
            Almacén de Arte, del 26 al 30 de mayo
          </p>
          <hr />
          <p className="text-star">
            2020 - Pinhole and Solarigraphy Month, muestra virtual del
            Experimental Photo Festival, Barcelona, España
          </p>
          <hr />
          <p className="text-star">
            2020 - Otra Feria Episodio 5 JAA (artista invitada por Almacén de
            Arte), Feria online de arte contemporáneo con ediciones en
            diferentes países, del 9 al 14 de agosto
          </p>
          <hr />
          <p className="text-star">
            2020 - “Una mirada estenopeica”, exposición online colectiva
            organizada por Chicali Pinholero, Mexicali, Mejico
          </p>
          <hr />
          <p className="text-star">
            2020 - “Estenopeicas sin fronteras”, séptima exposición online
            organizada por Oaxaca Estenopeica, Oaxaca, Mejico
          </p>
          <hr />
          <p className="text-star">
            2020 - “3ra Exposición Virtual Colectiva Internacional de Imagen
            Estenopeica y Solarigrafía” organizada por Laboratorio de
            experimentación fotográfica XA, Morelia, Mejico
          </p>
          <hr />
          <p className="text-star">
            2018 - “Carambola al 70%”, colectiva, Galería Carambola, Rosario,
            Santa Fe, Argentina
          </p>
          <hr />
          <p className="text-star">
            2017 - “VI Salón de Pequeño Formato”, colectiva, Espacio
            Institucional de Eventos de la Facultad de Humanidades y Artes
            U.N.R, Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p className="text-star">
            2017 - “ADA en el Roverano”, colectiva de la Asociación de
            Dibujantes Argentinos, Museo de Artes Visuales Victor Roverano,
            Buenos Aires, Argentina
          </p>
          <hr />
          <p className="text-star">
            2017 - “21x29.7”, colectiva, Galería Carambola, Rosario, Santa Fe,
            Argentina
          </p>
          <hr />
          <p className="text-star">
            2016 - “V Salón de Pequeño Formato”, colectiva, Centro Cultural
            Fontanarrosa, Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p className="text-star">
            2016 - “Arte y oficio”, muestra de obras restauradas del artista
            Erminio Blotta, Grupo de restauro Claudio Blotta, Biblioteca
            Argentina, Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p className="text-star">
            2015 - XI Bienal de cerámica, colectiva, Villa Gesell, Buenos Aires,
            Argentina
          </p>
          <hr />
          <p className="text-star">
            2015/2014 - “Del mar para ti”, colectiva internacional, Poca
            Gallery, Biskaia, España. Presentandose posteriormente dentro del
            proyecto Curio-Sea-Ty en Patrick Studios Project Space, Leeds,
            Inglaterra del 24 al 29 de marzo
          </p>
          <hr />
          <p className="text-star">
            2012 - “En pequeño formato”, colectiva y feria de arte, Ornato y
            Menaje, Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p className="text-star">
            2012 - “¡Estamos invitados a tomar el té!”, junto a Ariana Albert,
            Oficina 26 - Pasaje Pam, Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p className="text-star">
            2012/2011 - “5º Miniprint Rosario”, colectiva internacional en el
            marco de la Séptima Semana del Arte, Centro Cultural Bernardino
            Rivadavia, Rosario, ArgentinaPresentandose posteriormente en Museo
            MADHA y Centro Cultural Municipal de Arrecifes, Buenos aires;
            Escuela de Bellas Artes Regina Pacis, San Isidro, Buenos Aires; Sala
            Farina de la Ciudad de las Artes, Córdoba
          </p>
          <hr />
          <p className="text-star">
            2011 - “II Salón de Pequeño Formato”, colectiva, Sede de Gobierno
            U.N.R, Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p className="text-star">
            2011 - “Un cuarto de capricho”, colectiva de la cátedra del Taller
            de Pintura I de la Facultad de Humanidades y artes (U.N.R), Centro
            Cultural La Toma, Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p className="text-star">
            2010 - Colectiva de ilustración infantil, Biblioteca pedagógica
            Eudoro Díaz, Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p className="text-star">
            2010 - “Encomienda de zanahorias”, colectiva de ilustración infantil
            en el marco del Festival internacional de cine para niños y jóvenes,
            sala Melany del Centro de Arte MDQ, Mar del Plata, Buenos Aires,
            Argentina
          </p>
          <hr />
          <p className="text-star">
            2010 - “Tercer encuentro de dibujos en pequeño formato”, colectiva,
            edificio anexo José Pagés Giribet de la Fundación del Gran Rosario,
            Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p className="text-star">
            2010 - “33 Zanahorias Exponen”, colectiva de ilustración infantil,
            Jardín de los niños, Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p className="text-star">
            2010 - “La Colectiva de Fotos Séptima Edición”, colectiva de
            fotografía, Centro de Expresiones Contemporáneas, Rosario, Santa Fe,
            Argentina
          </p>
          <hr />
          <p className="text-star">
            2009 - Muestra colectiva, La Caterva galería tienda de arte,
            Rosario, Santa Fe, Argentina
          </p>
          <hr />
          <p className="text-star">
            2009 - Cátedra de pintura III de la Facultad de Humanidades y Artes
            (U.N.R), colectiva, Centro de Expresiones Contemporáneas, Rosario,
            Santa Fe, Argentina
          </p>
          <hr />
          <p className="text-star">
            2009 - “Arte Correo: Al borde”, colectiva internacional para la
            Quinta Semana del Arte, Fundación Centro Rosario, Rosario, Santa Fe,
            Argentina
          </p>
          <hr />
        </>
      ),
    },
  ];

  return (
    <>
      <div className="padProyectos" id="cv">
        <h1 className="text-center">Curriculum Vitae</h1>
        <div>
          <img
            className="imgRedonda floatRigth"
            src="/index/cv.jpg"
            alt="imagen de perfil de Paz Secundini"
          />
        </div>
        <p className="ps-md-5 text-start">
          Secundini María Paz <br />
          1 de mayo 1513 <br />
          2000 Rosario - Santa Fe - Argentina <br />
          07 de Diciembre de 1988 <br />
          Cuit: 27-34051531-0 <br />
          Nacionalidad: Argentina <br />
        </p>
        <hr />
        <div className="pb-4">
          <AccordionItem
            title="Idiomas"
            content={
              <>
                <p className="text-start">Castellano nativo</p>
                <hr />
                <p className="text-start">Inglés británico - Nivel avanzado</p>
                <hr />
              </>
            }
          ></AccordionItem>
          <AccordionItem
            title="Títulos"
            content={
              <>
                <p className="text-start">
                  Universidad Nacional de Rosario <br />
                  Facultad de Humanidades y Artes - Escuela de Bellas Artes{" "}
                  <br />
                  2014 <br />
                  Licenciada en Bellas Artes - Mención Pintura <br />
                  Promedio académico: 8,61 <br />
                  Promedio histórico: 8,61 <br />
                  Nota de tesina: 10
                </p>
                <hr />
                <p className="text-start">
                  Universidad del Salvador <br />
                  2022
                  <br />
                  Diplomada Universitaria en Estudios Irlandeses <br />
                  Nota final: 10
                </p>
                <hr />
              </>
            }
          ></AccordionItem>
          {data.map((item, index) => (
            <AccordionItem
              key={index}
              title={item.title}
              content={item.content}
            ></AccordionItem>
          ))}
          ;
        </div>

        <div className="fondoAzulado pb-2 pt-2">
          <a href="https://walink.co/4fccb4" target="_blank" rel="noreferrer">
            <i className="bi bi-whatsapp pe-lg-3"></i>
            <span className="p d-none d-lg-inline">+54 9 341 3779106</span>
          </a>
          <a
            href="mailto:mariapazsecundini@hotmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bi bi-envelope pe-lg-3"></i>
            <span className="p d-none d-lg-inline">
              mariapazsecundini@hotmail.com
            </span>
          </a>
          <a
            href="https://www.instagram.com/jardin.de.invierno/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bi bi-instagram pe-lg-3"></i>
            <span className="p d-none d-lg-inline">@jardin.de.invierno</span>
          </a>
          <a
            href="http://www.pazsecundini.com.ar"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bi bi-image pe-lg-3"></i>
            <span className="p d-none d-lg-inline">pazsecundini.com.ar</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default CV;
