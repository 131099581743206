import React from "react";
import Carousel from "../Carousel";

const LosLugaresDondeTeAme = () => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  return (
    <>
      {" "}
      <section id="losLugaresDondeTeAme">
        <div className="">
          <h2 className="d-md-inline">Los lugares donde te amé.</h2>
          <h2 className="fs-3 fs-md-none d-md-inline p-md-0 m-md-1">
            ¿Como podías saberlo?
          </h2>
        </div>
        <div className="container-fluid m-0 p-0">
          <div className="imgDesktop">
            <Carousel settings={settings}>
              <div className="">
                <img
                  src="/losLugaresDondeTeAme/los_lugares_donde_te_ame_I.jpg"
                  className="img-fluid"
                  alt="fotografia analogica"
                />
              </div>
              <div className="">
                <img
                  src="/losLugaresDondeTeAme/los_lugares_donde_te_ame_III.jpg"
                  className="img-fluid"
                  alt="fotografia analogica"
                />
              </div>
              <div className="">
                <img
                  src="/losLugaresDondeTeAme/los_lugares_donde_te_ame_IV.jpg"
                  className="img-fluid"
                  alt="fotografia analogica"
                />
              </div>
              <div className="">
                <img
                  src="/losLugaresDondeTeAme/los_lugares_donde_te_ame_VI.jpg"
                  className="img-fluid"
                  alt="fotografia analogica"
                />
              </div>
              <div className="">
                <img
                  src="/losLugaresDondeTeAme/los_lugares_donde_te_ame_VII.jpg"
                  className="img-fluid"
                  alt="fotografia analogica"
                />
              </div>
              <div className="">
                <img
                  src="/losLugaresDondeTeAme/los_lugares_donde_te_ame_VIII.jpg"
                  className="img-fluid"
                  alt="fotografia analogica"
                />
              </div>
              <div className="">
                <img
                  src="/losLugaresDondeTeAme/los_lugares_donde_te_ame_IX.jpg"
                  className="img-fluid"
                  alt="fotografia analogica"
                />
              </div>
              <div className="">
                <img
                  src="/losLugaresDondeTeAme/los_lugares_donde_te_ame_X.jpg"
                  className="img-fluid"
                  alt="fotografia analogica"
                />
              </div>
              <div className="">
                <img
                  src="/losLugaresDondeTeAme/los_lugares_donde_te_ame_XI.jpg"
                  className="img-fluid"
                  alt="fotografia analogica"
                />
              </div>
              <div className="">
                <img
                  src="/losLugaresDondeTeAme/los_lugares_donde_te_ame_XII.jpg"
                  className="img-fluid"
                  alt="fotografia analogica"
                />
              </div>
              <div className="">
                <img
                  src="/losLugaresDondeTeAme/los_lugares_donde_te_ame_XIII.jpg"
                  className="img-fluid"
                  alt="fotografia analogica"
                />
              </div>
            </Carousel>
          </div>
        </div>

        <p className="text-center  p-5 m-0">
          Camino por los parques con mi perro y mi Olympus Pen recordando
          fragmentos de las “Cartas al Rey de la Cabina” escritas por Pescetti.
          <br />
          <br />
        </p>
      </section>
    </>
  );
};

export default LosLugaresDondeTeAme;
