import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyDS9Af1gqInfezAonmcmgI8lh2s41MgbvE",
  authDomain: "paz-secundini-324e9.firebaseapp.com",
  databaseURL: "https://paz-secundini-324e9-default-rtdb.firebaseio.com",
  projectId: "paz-secundini-324e9",
  storageBucket: "paz-secundini-324e9.appspot.com",
  messagingSenderId: "377450539586",
  appId: "1:377450539586:web:18baaff9cc04cafe2adbdb",
  measurementId: "G-3BW3390KF7",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const dataBase = getFirestore(app);
const dataBaseRealTime = getDatabase(app);

// eslint-disable-next-line
const perf = getPerformance(app);

const storage = getStorage(app);

export { auth, dataBase, storage, dataBaseRealTime };
