import React from "react";

const ParquesNacionales = () => {
  return (
    <>
      <section id="parquesNacionales">
        <h2>Parques Nacionales</h2>
        <div className="container overflow-hidden">
          <div className="row">
            <figure className="img-container figure col-md-6">
              <img
                src="/parquesNacionales/un_parque_nacional_en_alaska.JPG"
                className="figure-img img-fluid rounded mx-auto d-block"
                alt="Escultura en pequeño formato un parque nacional en alaska"
              />
              <div className="textoSobre">
                Escultura cerámica en pequeño formato <br />
                Cerámica, óxidos, crayones, acuarelas, vidrios y esmalte con
                ceniza <br />
                5 x 11 x 15 cm <br />
                2020
              </div>
              <figcaption className="figure-caption text-center">
                Un parque nacional en Alaska
              </figcaption>
            </figure>
            <figure className="figure col-md-3">
              <img
                src="/parquesNacionales/fogata_para_la_nostalgia.JPG"
                className="figure-img img-fluid rounded mx-auto d-block"
                alt="Escultura en pequeño formato fogata para la nostalgia"
              />
              <div className="textoSobre">
                Escultura cerámica en pequeño formato <br />
                Cerámica, óxidos, crayones y esmaltes con ceniza <br />
                5 x 16 x 16 cm <br />
                2021
              </div>
              <figcaption className="figure-caption text-center">
                Fogata para la nostalgia
              </figcaption>
            </figure>
            <figure className="figure col-md-3">
              <img
                src="/parquesNacionales/celtas.JPG"
                className="figure-img img-fluid rounded mx-auto d-block"
                alt="escultura ceramica en pequeño formato celtas"
              />
              <div className="textoSobre">
                Escultura cerámica en pequeño formato <br />
                Cerámica, óxidos, crayones, esmalte con ceniza y mica cordobesa
                <br />
                4 x 15 x 15 cm <br />
                2021
              </div>
              <figcaption className="figure-caption text-center">
                Celtas
              </figcaption>
            </figure>
          </div>
          <div className="row">
            <figure className="figure col-md-4">
              <img
                src="/parquesNacionales/a_una_roca_y_un_oceano_de_distancia.JPG"
                className="mx-auto d-block figure-img img-fluid rounded"
                alt="escultura ceramica en pequeño formado a una roca y un oceano de distancia"
              />
              <figcaption className="figure-caption text-center">
                A una roca y un océano de distancia
              </figcaption>
              <div className="textoSobre">
                Escultura cerámica en pequeño formato <br />
                Cerámica ocre y esmalte con ceniza
                <br />
                Medidas variables <br />
                2020
              </div>
            </figure>
            <figure className="figure col-md-4">
              <img
                src="/parquesNacionales/el_fondo_azul_de_mis_suenios.JPG"
                className="figure-img img-fluid rounded mx-auto d-block"
                alt="escultura ceramica en pequeño formato el fondo azul de mis sueños"
              />
              <figcaption className="figure-caption text-center">
                El fondo azul de mis sueños
              </figcaption>
              <div className="textoSobre">
                Escultura cerámica en pequeño formato <br />
                Cerámica, óxido de cobalto, vidrio de color, esmalte con ceniza
                y esmaltina con ceniza volcánica
                <br />
                5 x 7.5 x 8.5 cm <br />
                2021
              </div>
            </figure>

            <figure className="figure col-md-4">
              <img
                src="/parquesNacionales/tenia_dos_anios_y_un_caracol.JPG"
                className="figure-img img-fluid rounded mx-auto d-block"
                alt="escultura ceramica en pequeño formato tenia dos años y un caracol"
              />
              <figcaption className="figure-caption text-center">
                Tenía dos años y un caracol
              </figcaption>
              <div className="textoSobre">
                Escultura cerámica en pequeño formato <br />
                Cerámica, óxidos, crayones, esmaltes con ceniza, esmalte
                comercial, vidrio y mica cordobesa <br />
                4.5 x 11 x 11 cm <br />
                2021
              </div>
            </figure>
          </div>
          <div className="row">
            <figure className="figure col-md-3">
              <img
                src="/parquesNacionales/islandia.JPG"
                className="figure-img img-fluid rounded mx-auto d-block"
                alt="escultura ceramica en pequeño formato islandia"
              />
              <figcaption className="figure-caption text-center">
                Islandia
              </figcaption>
              <div className="textoSobre">
                Escultura cerámica en pequeño formato <br />
                Cerámica, óxidos, crayones, esmaltes con ceniza
                <br />
                Medidas variables <br />
                2019
              </div>
            </figure>
            <figure className="figure col-md-3">
              <img
                src="/parquesNacionales/las_rocas_azules_del_recuerdo.JPG"
                className="figure-img img-fluid rounded mx-auto d-block"
                alt="escultura ceramica en pequeño formato las rocas azules del recuerdo"
              />
              <figcaption className="figure-caption text-center">
                Las rocas azules del recuerdo
              </figcaption>
              <div className="textoSobre">
                Escultura cerámica en pequeño formato <br />
                Cerámica, óxido de cobalto, crayones, vidrio de color, esmaltes
                con cenizas y esmaltina de ceniza volcánica
                <br />
                5.5 x 6 x 8 cm <br />
                2021
              </div>
            </figure>
            <figure className="figure col-md-3">
              <img
                src="/parquesNacionales/que_somos comparados_con_rocas _y_montanias.JPG"
                className="figure-img img-fluid rounded mx-auto d-block"
                alt="escultura ceramica en pequeño formato que somos comparados con rocas y montañas"
              />
              <figcaption className="figure-caption text-center">
                Que somos comparados con rocas y montañas
              </figcaption>
              <div className="textoSobre">
                Escultura cerámica en pequeño formato <br />
                Cerámica con chamote, óxidos, crayones, esmaltes con ceniza
                <br />
                5 x 10 x 10 cm <br />
                2020
              </div>
            </figure>
            <figure className="figure col-md-3">
              <img
                src="/parquesNacionales/amuletos.JPG"
                className="figure-img img-fluid rounded mx-auto d-block"
                alt="escultura ceramica en pequeño formato amuletos"
              />
              <figcaption className="figure-caption text-center">
                Amuletos
              </figcaption>
              <div className="textoSobre">
                Escultura cerámica en pequeño formato <br />
                Cerámica, óxido de cobalto y esmalte comercial. Hilo de bordar y
                lumen prints de flores, helechos y líquenes
                <br />
                Medidas variables <br />
                2022
              </div>
            </figure>
          </div>

          <div className="row">
            <figure className="figure col-md-4">
              <img
                src="/parquesNacionales/los_liquenes_y_el_acantilado.JPG"
                className="figure-img img-fluid rounded mx-auto d-block"
                alt="escultura ceramica en pequeño formato los liquenes y el acantilado"
              />
              <figcaption className="figure-caption text-center">
                Los líquenes y el acantilado
              </figcaption>
              <div className="textoSobre">
                Escultura cerámica en pequeño formato <br />
                Cerámica, óxido de cobalto y esmalte con ceniza y ramitas del
                monte entrerriano
                <br />
                8 cm x 10 cm x 10 cm
                <br />
                2022
              </div>
            </figure>
            <figure className="figure col-md-4">
              <img
                src="/parquesNacionales/los_paisajes_en_mis_suenios_I.JPG"
                className="figure-img img-fluid rounded mx-auto d-block"
                alt="escultura ceramica en pequeño formato los paisajes de mis sueños I"
              />
              <figcaption className="figure-caption text-center">
                Los paisajes en mis sueños I
              </figcaption>
              <div className="textoSobre">
                Escultura cerámica en pequeño formato <br />
                Cerámica, óxidos, crayones, esmaltes con ceniza y esmaltes
                comerciales <br />
                5 x 6 x 10 cm <br />
                2020
              </div>
            </figure>
            <figure className="figure col-md-4">
              <img
                src="/parquesNacionales/el_oceano_en_medio_de_mi.JPG"
                className="figure-img img-fluid rounded mx-auto d-block"
                alt="escultura ceramica en pequeño formato el oceano en medio de mi"
              />
              <figcaption className="figure-caption text-center">
                El océano en medio de mí
              </figcaption>
              <div className="textoSobre">
                Escultura cerámica en pequeño formato <br />
                Céramica, óxido de cobalto, vidrio, esmalte con ceniza, y
                ecoprint de hojas y líquenes <br />
                5 x 10 x 13 cm <br />
                2022
              </div>
            </figure>
          </div>
        </div>
      </section>
    </>
  );
};

export default ParquesNacionales;
