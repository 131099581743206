import React from "react";
import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import ItemDetailContent from "./ItemDetailContent";
import Home from "./Home";
import Show from "./Show";
import Nav from "./Nav";
import { useLocation } from "react-router-dom";
import { CartProvider } from "../context/CartContext";
import Proyecto from "./proyectos/Proyecto";
import ItemListContainer from "./ItemListContainer";
import CV from "./CV";

const Main = () => {
  const location = useLocation().pathname;
  const [showCart, setShowCart] = useState(false);
  const handleCartModal = () => {
    setShowCart(!showCart);
  };
  const handleScrollTo = (hash) => {
    const hashRef = document.querySelector(hash);
    if (hashRef) {
      const { top } = hashRef.getBoundingClientRect();
      window.scrollTo({
        top: top + window.pageYOffset - 63,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <>
      {location !== "/admin" ? (
        <>
          <Nav handleScrollTo={handleScrollTo} />
          <div className="container-fluid sinBorde">
            <Routes>
              <Route
                path="/"
                element={<Home handleScrollTo={handleScrollTo} />}
              />
              <Route
                path="/tienda"
                element={
                  <CartProvider>
                    <ItemListContainer handleCartModal={handleCartModal} />
                  </CartProvider>
                }
              />
              <Route
                path="/detalle/:idProducto"
                element={
                  <CartProvider>
                    <ItemDetailContent handleCartModal={handleCartModal} />
                  </CartProvider>
                }
              />
              <Route path="/proyecto" element={<Proyecto />} />
              <Route path="/proyecto/:urlProyecto" element={<Proyecto />} />
              {/* Esta ruta permite pasar la URL del proyecto como parámetro */}
              <Route path="/curriculum-vitae" element={<CV />} />
              <Route path="/admin" element={<Show />} />
            </Routes>
          </div>
        </>
      ) : (
        <div className="container-fluid sinBorde">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/admin" element={<Show />} />
          </Routes>
        </div>
      )}
    </>
  );
};

export default Main;
