import React, { useRef } from "react";
import Swal from "sweetalert2";

export const FormContacto = () => {
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);

    try {
      const response = await fetch("send_email.php", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        Swal.fire({
          text: "Gracias por escribirme. Te responderé pronto",
          iconHtml: `<i class="bi bi-snow2 custom-icon-class"></i>`,
          showConfirmButton: true,
          confirmButtonText: "Ok",
          style: {
            text: {
              fontSize: "32px",
            },
          },
          className: {
            icon: "custom-icon-class",
            text: "custom-text",
          },
          confirmButtonClass: "boton",
        });
        form.current.reset();
      } else {
        console.log("Error al enviar el correo");
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  return (
    <>
      <form id="form" ref={form} onSubmit={sendEmail}>
        <div className="formulario">
          <label>Nombre</label>
          <input type="text" name="name" required />
          <label>Email</label>
          <input type="email" name="email" required />
          <label>Mensaje</label>
          <textarea name="message" rows={5} />
          <div className="d-flex mt-4 mb-2"></div>
          <div className="col-12 text-center">
            <input className="botonForm" type="submit" value="Enviar" />
          </div>
        </div>
      </form>
    </>
  );
};

// import { useState } from "react";
// import Swal from "sweetalert2";
// import icono from '../img/detalle.png'

// export const FormContacto = () => {

//   const [nombre, setNombre] = useState("");
//   const [mensaje, setMensaje] = useState("");

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     let deviceType = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
//       ? "api"
//       : "web";
//     const whatsapp = `Hola *La luna en un lapicero* 🌕. Soy ${nombre} y mi mensaje es: ${mensaje}`;
//     let linkCompra = `https://${deviceType}.whatsapp.com/send?phone=59897453324&text=${whatsapp}`;
//     window.open(linkCompra, "_blank");

//     // Muestra el mensaje de agradecimiento
//     Swal.fire({
//       text: 'Gracias por contactarnos!',
//       iconHtml: `<img src="${icono}" alt="Icono personalizado" width="100"/>`,
//       showConfirmButton: true,
//       confirmButtonText: 'Ta!',
//       customClass: {
//         icon: 'custom-icon-class',
//       },
//       confirmButtonClass: 'btnSweet'
//     });
//   };

//   const handleChangeName = (event) => {
//     setNombre(event.target.value);
//   };

//   const handleChangeMensaje = (event) => {
//     setMensaje(event.target.value);
//   };

//   return (
//     <>
//       <form id="form" onSubmit={handleSubmit}>
//         <div className="formulario">
//           <label>Nombre</label>
//           <input
//             type="text"
//             required
//             placeholder="tu nombre"
//             name="nombre"
//             value={nombre}
//             onChange={handleChangeName}
//           />
//           <label>Mensaje</label>
//           <textarea
//             type="text"
//             rows="5"
//             placeholder="Mandanos un mensaje de WhatsApp"
//             name="mensaje"
//             value={mensaje}
//             onChange={handleChangeMensaje}
//           />

//           <div className="col-12 text-center">
//             <input className="botonForm" type="submit" value="Enviar" />
//           </div>
//         </div>
//       </form>
//     </>
//   );
// };
