import React from "react";

const HornearEnPozo = () => {
  return (
    <>
      <section id="hornearEnPozo">
        <h2>Hornear en pozo</h2>
        <div className="container-fluid overflow-hidden">
          <div className="row">
            <img
              className="col-4 col-sm-3 img-fluid"
              src="/hornearEnPozo/hornear_en_pozo_I.JPG"
              alt="piezas de ceramica alrededor de un pozo con fuego"
            />
            <img
              className="col-8 col-sm-6"
              src="/hornearEnPozo/hornear_en_pozo_II.JPG"
              alt="piezas de ceramica en un pozo con cenizas"
            />
            <img
              className="col-6 col-sm-3"
              src="/hornearEnPozo/hornear_en_pozo_III.JPG"
              alt="pozo con fuego y piezas de ceramica"
            />

            <img
              className="col-6 col-sm-4 img-fluid"
              src="/hornearEnPozo/hornear_en_pozo_IV.JPG"
              alt="pozo con brazas y piezas de ceramica"
            />
            <img
              className="col-6 col-sm-4"
              src="/hornearEnPozo/hornear_en_pozo_V.JPG"
              alt="paz secundini sentada frente a pozo con fuego"
            />
            <img
              className="col-6 col-sm-4 img-fluid"
              src="/hornearEnPozo/hornear_en_pozo_VII.JPG"
              alt="piezas de ceramica recien sacadas de un pozo con cenizas"
            />

            <img
              className="col-4 col-sm-4"
              src="/hornearEnPozo/hornear_en_pozo_VI.JPG"
              alt="pieza de ceramica entre las cenizas"
            />
            <img
              className="col-8 col-sm-8"
              src="/hornearEnPozo/hornear_en_pozo_VIII.JPG"
              alt="azucarera sostenida por una mano sobre fondo de pozo con cenizas"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HornearEnPozo;
