import React from "react";

const AccordionItem = ({ title, content }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="accordion-item">
      <h3 className="accordion-header" onClick={handleClick}>
        <i className="pe-3 bi bi-arrow-down-circle-fill"></i>
        {title}
      </h3>
      {isExpanded && (
        <div className="accordion-collapse collapse show">
          <div className="accordion-body">{content}</div>
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
