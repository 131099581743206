import React from "react";

const LosPaisajesDelFrio = () => {
  return (
    <>
      <section id="losPaisajesDelFrio">
        <h2>Los paisajes del frío</h2>
        <div className="container-fluid overflow-hidden">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="cajaImg mt-md-4">
                <img
                  src="/losPaisajesDelFrio/Beagle_lichens.jpg"
                  className="img-fluid"
                  alt="Paisaje de rocas y montañas"
                />
                <p className="text-center">
                  Beagle lichens <br />
                  <span className="letraComun">zenit - kodak 50d</span>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="cajaImg m-md-1">
                <img
                  src="/losPaisajesDelFrio/blue_Beagle.jpg"
                  className="img-fluid"
                  alt="canal de beagle con filtro azul"
                />
                <p className="text-center">
                  Blue Beagle <br />
                  <span className="letraComun">
                    zenit - kodak 50d + filtro azul
                  </span>
                </p>
              </div>
              <div className="cajaImg mt-md-3">
                <img
                  src="/losPaisajesDelFrio/Beagle_seaweed.jpg"
                  className="img-fluid"
                  alt="canal de beagle mar y montañas"
                />
                <p className="text-center">
                  Beagle seaweed <br />
                  <span className="letraComun">zenit - kodak 50d</span>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="cajaImg">
                <img
                  src="/losPaisajesDelFrio/blue_and_yellow_landscape.jpg"
                  className="img-fluid"
                  alt="flores amarillas y montaña"
                />
                <p className="text-center">
                  Blue and yellow landscape <br />
                  <span className="letraComun">zenit - kodak proimage</span>
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <div className="cajaImg">
                <img
                  src="/losPaisajesDelFrio/tren_del_fin_del_mundo_I.jpg"
                  className="img-fluid"
                  alt="tren rojo del fin del mundo"
                />
                <p className="text-center">
                  Tren del fin del mundo <br />
                  <span className="letraComun">zenit - kodak 50d</span>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="cajaImg ms-md-3">
                <img
                  src="/losPaisajesDelFrio/les_eclaireurs_II.jpg"
                  className="img-fluid"
                  alt="faro del fin del mundo, rocas y nubes"
                />
                <p className="text-center">
                  Les Eclaireurs II <br />
                  <span className="letraComun">zenit - kodak 50d</span>
                </p>
              </div>
              <div className="cajaImg mt-md-3 me-md-3">
                <img
                  src="/losPaisajesDelFrio/by_the_riverside.jpg"
                  className="img-fluid"
                  alt="tronco sobre la costa del rio"
                />
                <p className="text-center">
                  By the riverside <br />
                  <span className="letraComun">zenit - kodak proimage</span>
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4">
              <div className="cajaImg">
                <img
                  src="/losPaisajesDelFrio/Beagle_seaweed.jpg"
                  className="img-fluid"
                  alt="Canal de Beagle"
                />
                <p className="text-center">
                  Beagle seaweed <br />
                  <span className="letraComun">zenit - kodak 50d</span>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-1">
              <div className="cajaImg ms-md-5">
                <img
                  src="/losPaisajesDelFrio/lichen_on_a_tree.jpg"
                  className="img-fluid"
                  alt="arbol con liquenes"
                />
                <p className="text-center">
                  Lichen on a tree <br />
                  <span className="letraComun">zenit - kodak proimage</span>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-md-2">
              <div className="cajaImg">
                <img
                  src="/losPaisajesDelFrio/les_eclaireurs_I.jpg"
                  className="img-fluid"
                  alt="mar y fondo de faro del fin del mundo"
                />
                <p className="text-center">
                  Les Eclaireurs I <br />
                  <span className="letraComun">zenit - kodak 50d</span>
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4">
              <div className="cajaImg">
                <img
                  src="/losPaisajesDelFrio/winter_corner.jpg"
                  className="img-fluid"
                  alt="planta con frutos rojos"
                />
                <p className="text-center">
                  Winter corner <br />
                  <span className="letraComun">zenit - kodak proimage</span>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="cajaImg me-md-5">
                <img
                  src="/losPaisajesDelFrio/fallen_tree.jpg"
                  className="img-fluid"
                  alt="arbol con liquenes"
                />
                <p className="text-center">
                  Fallen tree <br />
                  <span className="letraComun">zenit - kodak 250d </span>
                </p>
              </div>
              <div className="cajaImg ms-md-5">
                <img
                  src="/losPaisajesDelFrio/iceberg.jpg"
                  className="img-fluid"
                  alt="iceberg en un lago."
                />
                <p className="text-center">
                  Iceberg <br />
                  <span className="letraComun">zenit - kodak 250d </span>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-2">
              <div className="cajaImg">
                <img
                  src="/losPaisajesDelFrio/cottage.jpg"
                  className="img-fluid"
                  alt="mar y fondo de faro del fin del mundo"
                />
                <p className="text-center">
                  Cottage <br />
                  <span className="letraComun">zenit - kodak 50d</span>
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4">
              <div className="cajaImg">
                <img
                  src="/losPaisajesDelFrio/icebergs.jpg"
                  className="img-fluid"
                  alt="icebergs"
                />
                <p className="text-center">
                  Icebergs <br />
                  <span className="letraComun">zenit - kodak 250d</span>
                </p>
              </div>
              <div className="cajaImg me-md-1">
                <img
                  src="/losPaisajesDelFrio/landscape.jpg"
                  className="img-fluid"
                  alt="Lago"
                />
                <p className="text-center">
                  Landscape <br />
                  <span className="letraComun">zenit - kodak 50d</span>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-1">
              <div className="cajaImg">
                <img
                  src="/losPaisajesDelFrio/old_ship.jpg"
                  className="img-fluid"
                  alt="barco viejo."
                />
                <p className="text-center">
                  Old ship <br />
                  <span className="letraComun">zenit - kodak 50d</span>
                </p>
              </div>
              <div className="cajaImg  me-md-1">
                <img
                  src="/losPaisajesDelFrio/sobre_el_canal_de_beagle.jpg"
                  className="img-fluid"
                  alt="canal de Beagle."
                />
                <p className="text-center">
                  Sobre el canal de Beagle <br />
                  <span className="letraComun">zenit - kodak 50d</span>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="cajaImg">
                <img
                  src="/losPaisajesDelFrio/ushuaia.jpg"
                  className="img-fluid"
                  alt="Oficina postal."
                />
                <p className="text-center">
                  Ushuaia <br />
                  <span className="letraComun">zenit - kodak 50d</span>
                </p>
              </div>
              <div className="cajaImg  me-md-1">
                <img
                  src="/losPaisajesDelFrio/tren_del_fin_del_mundo.jpg"
                  className="img-fluid"
                  alt="Tren del fin del mundo."
                />
                <p className="text-center">
                  Los trenes y el bosque <br />
                  <span className="letraComun">zenit - kodak 50d</span>
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-3">
              <div className="cajaImg">
                <img
                  src="/losPaisajesDelFrio/autumn.jpg"
                  className="img-fluid"
                  alt="bosque."
                />
                <p className="text-center">
                  Autumn <br />
                  <span className="letraComun">zenit - kodak 250d</span>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="cajaImg">
                <img
                  src="/losPaisajesDelFrio/fog.jpg"
                  className="img-fluid"
                  alt="arbol y roca."
                />
                <p className="text-center">
                  Fog <br />
                  <span className="letraComun">zenit - kodak 250d </span>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="cajaImg">
                <img
                  src="/losPaisajesDelFrio/parque_nacional_los_glaciares.jpg"
                  className="img-fluid"
                  alt="lago y montañas."
                />
                <p className="text-center">
                  Parque Nacional Los Glaciares <br />
                  <span className="letraComun">zenit - kodak 250d </span>
                </p>
              </div>
            </div>
            <div className="col-3 d-none d-md-block"></div>
            <div className="col-12 col-md-6">
              <div className="cajaImg">
                <img
                  src="/losPaisajesDelFrio/losPaisajes.jpg"
                  className="img-fluid"
                  alt="bosque"
                />
                <p className="text-center">
                  Forest <br />
                  <span className="letraComun">zenit - kodak 250d </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LosPaisajesDelFrio;
