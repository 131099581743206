import React from "react";
import { Link } from "react-router-dom";

const Statement = (props) => {
  return (
    <>
      <section id="statement">
        <h2>Statement</h2>
        <div className="pb-5 container-fluid overflow-hidden">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="d-block mx-auto">
                <img
                  src="/index/cold_sky.jpg"
                  alt="Gaviota sobre el lago y las montañas"
                  className="d-block mx-auto"
                />
                <p className="text-center">
                  Trabajo con elementos orgánicos y soy de invierno.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-block mx-auto">
                <img
                  src="/index/two_lungs.JPG"
                  alt="Cianotipia botanica azul"
                  className="d-block mx-auto"
                />
                <p className="text-center">
                  En mis obras los materiales que dan la forma son también la
                  nieve, la montaña rocosa, el bosque y la cascada, es eso lo
                  que busco atrapar.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-block mx-auto">
                <img
                  src="/index/foraging.JPG"
                  alt="Piedras hojas musgo y frutos"
                  className="d-block mx-auto"
                />
                <p className="text-center">
                  En estos lugares tan poco habitados, como en mi, prevalece la
                  naturaleza. Con la elección de materiales vivos y sus procesos
                  cuestiono las prácticas de consumo actuales.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-block mx-auto">
                <img
                  src="/index/tulips_in_the_andes_mountains.jpg"
                  alt="Campo de tulipanes en los andes"
                  className="d-block mx-auto"
                />
                <p className="text-center">
                  Así doy lugar a obras sujetas a un ciclo natural similar al
                  nuestro que intentan hacernos reflexionar sobre las
                  problemáticas ambientales y la forma de relacionarnos con
                  otras especies.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Link
          to="#contacto"
          className=""
          onClick={() => props.handleScrollTo("#contacto")}
        >
          <div className="flecha">¡</div>
        </Link>
      </section>
    </>
  );
};

export default Statement;
