import React, { useCallback, useState, useRef } from "react";
import { storage } from "../firebaseConfig";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

function ImageUpload({ subirImagenes, updateFile, size = 600 }) {
  const [fileName, setFileName] = useState(updateFile ? updateFile.name : "");
  const [url, setUrl] = useState(updateFile ? updateFile.url : "");
  const [isLoading, setIsLoading] = useState(false);

  const handleFile = useCallback(
    (event) => handleUpload(event.target.files[0]),
    [subirImagenes, fileName]
  );

  const handleUpload = async (file) => {
    const storageRef = ref(storage, `/files/${file.name}`);

    // Reducir tamaño de imagen
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = size;
        const scaleSize = MAX_WIDTH / img.width;
        canvas.width = MAX_WIDTH;
        canvas.height = img.height * scaleSize;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(
          (blob) => {
            const uploadTask = uploadBytesResumable(storageRef, blob);

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
              },
              (err) => console.log(err),
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                  setUrl(url);
                  const uploadedFileName = uploadTask.snapshot.ref.name;
                  setFileName(uploadedFileName); // Actualizar fileName con el nombre de archivo cargado
                  subirImagenes({ name: uploadedFileName, url: url }, false); // Utilizar el nombre de archivo cargado en lugar de fileName
                });
              }
            );
            setIsLoading(true); // Establecer isLoading a true antes de la carga
            uploadTask.then(() => {
              setIsLoading(false); // Establecer isLoading a false después de la carga
            });
          },
          "image/jpeg",
          1
        );
      };
    };
  };

  const borrarImagen = () => {
    if (!fileName) return;
    subirImagenes(fileName, 1);
    const storageRef = ref(storage, `/files/${fileName}`);
    deleteObject(storageRef)
      .then(() => {
        console.log(`Se borró la imagen ${fileName}`);
        setFileName("");
        setUrl("");
      })
      .catch((error) => {
        subirImagenes(fileName, 1);
        setFileName("");
        setUrl("");
        console.log(`Error al borrar la imagen ${fileName}:`, error);
      });
  };

  const fileInputRef = useRef();
  return (
    <>
      {url ? (
        <>
          <img src={url} width="100" alt={fileName} />
          <i onClick={borrarImagen} className=" btn bi bi-trash3"></i>
        </>
      ) : (
        <>
          <button
            className="custom-file-upload fs-1 btn"
            onClick={() => {
              if (!isLoading) {
                fileInputRef.current.click();
              }
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="fs-6">Cargando...</div>
            ) : (
              <i className="bi bi-sunglasses"></i>
            )}
            <input
              className="d-none"
              ref={fileInputRef}
              type="file"
              onChange={handleFile}
            />
          </button>
        </>
      )}
      {isLoading && (
        <div
          className="modal-loading-overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(255, 255, 255, 0.7)",
          }}
        >
          <div className="modal-loading-spinner"></div>
        </div>
      )}
    </>
  );
}

export default ImageUpload;
