import React from "react";

const ForagingforPigments = () => {
  return (
    <>
      <section id="foragingForPigments">
        <h2>Foraging for pigments</h2>
        <p className="ps-5 pe-5 fs-4">
          Mi forma de crear presenta una primera instancia donde recolecto
          pigmentos, tintes, arcillas, entre otros para ser tratados en mi
          taller. Cada material tiene un proceso especial que va a depender
          también del soporte y que por lo general suelen ser procesos largos
          que llevan varias semanas.
        </p>
        <div className="container-fluid overflow-hidden">
          <div className="cajaDeCajas">
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/almod_peel_ink_and_crystals.JPG"
                alt="tinta de almendras y cristales."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/autumn_leaves.jpg"
                alt="instantanea de hojas secas"
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/beewax_crayons_from_natural_pigments.JPG"
                alt="crayones de cera de abeja."
              />
            </div>

            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/colores_de_Yacanto.JPG"
                alt="muestras de tintas naturales sobre papel"
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/flowers_for_ink.JPG"
                alt="frascos con flores para hacer tinta."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/flowers_ink_and_amatista.JPG"
                alt="tinta de flores con amatista."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/foraged_soils_on_ceramic.JPG"
                alt="pigmentos minerales sobre ceramica."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/fresh_leaves_for_ecoprint.jpg"
                alt="estampa de hojas frescas sobre tela."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/gallnut_pigment.JPG"
                alt=""
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/galnut_ink.JPG"
                alt="dibujo de tinta de nogal."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/handmade_natural_watercolors.JPG"
                alt="acuarelas de pigmentos naturales en caracoles, bellotas, cascaras de nueces, de mani. "
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/las_formas_del_agua_I.JPG"
                alt="pintura de manchas de tinta natural sobre papel."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/las_formas_del_agua_II.JPG"
                alt="pintura de manchas de tinta natural sobre papel."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/las_formas_del_agua_III.JPG"
                alt="pintura de manchas de tinta natural sobre papel."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/las_formas_del_agua_IV.JPG"
                alt="pintura de manchas de tinta natural sobre papel."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/foraging (2).jpg"
                alt="cultivo"
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/natural_ink_bottles.JPG"
                alt="pequeñas botellas con tinta natural. "
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/natural_inks_on_paper.JPG"
                alt="muestras de tintas naturales sobre papel."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/natural_watercolors_on_a_shell.JPG"
                alt="acuarela en una caracola."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/soils_from_puerto_madryn.JPG"
                alt="rocas recolectadas en Puerto Madryn."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/take_me_north.JPG"
                alt="dibujo de tinta de urukum sobre papel."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/teloschistes_lichen.JPG"
                alt="fotografia de un liquen."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/clorotipia_hora_del_te.JPG"
                alt="Clorotipia juego de té sobre una hoja de árbol."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/un_balde.jpg"
                alt="Clorotipia juego de té sobre una hoja de árbol."
              />
            </div>
            <div className="cajaImagen">
              <img
                className=""
                src="/foragingforPigments/foraging (1).jpg"
                alt="Crayon"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForagingforPigments;
