import React, { useLayoutEffect } from "react";
import Proyectos from "./Proyectos";
import { Link } from "react-router-dom";
import Contacto from "./Contacto";
import Bio from "./Bio";
import Statement from "./Statement";
const Home = (props) => {
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   console.log("me cargue")
  //   },2000);
  // }, []);

  useLayoutEffect(() => {
    const handleLoad = () => {
      const hash = window.location.hash;
      if (hash) {
        props.handleScrollTo(hash);
      }
    };

    const timeoutId = setTimeout(() => {
      handleLoad();
    }, 100);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* {isLoading ? <Loader />:""} */}
      <>
        <section id="inicio">
          <Link className="" to="/curriculum-vitae">
            <h1 className="paz">Paz Secundini</h1>
          </Link>
          <Link
            to="#proyectos"
            className="flecha"
            onClick={() => props.handleScrollTo("#proyectos")}
          >
            <div className="flecha">¡</div>
          </Link>
        </section>

        <Proyectos handleScrollTo={props.handleScrollTo} />

        <Bio handleScrollTo={props.handleScrollTo} />

        <Statement handleScrollTo={props.handleScrollTo} />

        <Contacto />
      </>
    </>
  );
};

export default Home;
