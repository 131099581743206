import React from "react";

const SuelosDelSur = () => {
  return (
    <>
      <section id="suelosDelSur">
        <h2>Suelos del sur</h2>
        <div className="container-fluid overflow-hidden">
          <div className="row">
            <div className="col-md-8">
              <p className="ps-md-5 text-start fs-4">
                Pienso que es muy poético buscar y obtener color de un lugar
                determinado. <br />
                Trabajar de esta manera me permite extraer la cromaticidad
                intrínseca a cada entorno particular.
                <br />
                Esta serie surge como resultado de la recolección de pequeños
                fragmentos de suelos con los que me encontré en mis dos viajes
                al sur realizados a finales de{" "}
                <span className="fs-6 p">2021</span> y principios de{" "}
                <span className="fs-6 p">2022</span>.
              </p>
            </div>
            <figure className="col-md-4 cajaFigure d-none d-md-block">
              <img
                className="imgDefor"
                src="/suelosDelSur/suelos_del_sur_V.JPG"
                alt="Muestra ceramica de suelos de la peninsula de valdes"
              />
            </figure>
            <img
              className="d-md-none"
              src="/suelosDelSur/suelos_del_sur_V.JPG"
              alt="Muestra ceramica de suelos de la peninsula de valdes"
            />
          </div>
          <div className="row">
            <img
              className="col-md-4 img-fluid"
              src="/suelosDelSur/suelos_del_sur_I.JPG"
              alt="Muestra ceramica de suelos de puerto madryn"
            />
            <img
              className="col-md-4 img-fluid"
              src="/suelosDelSur/suelos_del_sur_II.JPG"
              alt="Muestra ceramica de suelos de puerto madryn"
            />
            <img
              className="col-md-4 img-fluid"
              src="/suelosDelSur/suelos_del_sur_IV.JPG"
              alt="Muestra ceramica de suelos de las grutas"
            />
          </div>
          <div className="row">
            <figure
              className="figure d-none d-md-flex col-12"
              dangerouslySetInnerHTML={{
                __html: `<iframe
                  src="https://www.google.com/maps/d/embed?mid=1ULDHHjWWyE5mlwnfPRQJCM_IdE-HCrww&ehbc=2E312F"
                  width="100%"
                  height="550"
                  sandbox="allow-scripts allow-forms allow-same-origin"
                ></iframe>`,
              }}
            />
          </div>
          <div className="row">
            <img
              className="col-md-4 img-fluid"
              src="/suelosDelSur/suelos_del_sur_VI.JPG"
              alt="Muestra ceramica de suelos del lago fagnano"
            />
            <img
              className="col-md-4 img-fluid"
              src="/suelosDelSur/suelos_del_sur_VII.JPG"
              alt="Muestra ceramica de suelos del glaciar martial"
            />
            <img
              className="col-md-4 img-fluid"
              src="/suelosDelSur/suelos_del_sur_VIII.JPG"
              alt="Muestra ceramica de suelos de el calafate"
            />
          </div>
          <div className="row">
            <figure
              className="figure d-md-none col-12"
              dangerouslySetInnerHTML={{
                __html: `<iframe src="https://www.google.com/maps/d/embed?mid=1ULDHHjWWyE5mlwnfPRQJCM_IdE-HCrww&ehbc=2E312F" width="100%" height="600"></iframe>`,
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default SuelosDelSur;
