import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

function Nav(props) {
  const windowWidth = window.innerWidth;

  const [isScrolled, setIsScrolled] = useState(false);

  const pantalla = windowWidth > 768 ? 0.45 : 0.17;

  useEffect(() => {
    const handleScroll = () => {
      const scrollDistance = window.pageYOffset;
      if (scrollDistance > window.innerHeight * pantalla) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pantalla]);
  const cualPath =
    window.location.pathname === "/proyectos/la_libreta_de_las_tapas_negras/" ||
    window.location.pathname === "/proyectos/la_libreta_de_las_tapas_negras"
      ? "scrollNegro"
      : "scrollFinal";

  return (
    <nav
      className={
        window.location.pathname !== "/"
          ? cualPath
          : isScrolled
          ? "scrollFinal"
          : "scrollInicial"
      }
    >
      <div className="navbarContainer">
        <div className={isScrolled ? "d-none d-lg-flex logo" : "d-none"}>
          <Link
            to="/curriculum-vitae"
            onClick={() => props.handleScrollTo("#inicio")}
          >
            Paz Secundini
          </Link>
        </div>
        <Link
          to="/#inicio"
          onClick={() => props.handleScrollTo("#inicio")}
          className={window.location.hash === "#inicio" ? "active" : ""}
        >
          <div className="d-none d-md-block textBarra">Inicio</div>
          <img
            src="/index/lluvioso.gif"
            className="img-fluid d-md-none"
            alt="icono"
          ></img>
        </Link>
        <Link
          to="/#proyectos"
          onClick={() => props.handleScrollTo("#proyectos")}
          className={window.location.hash === "#proyectos" ? "active" : ""}
        >
          <div className="d-none d-md-block textBarra">Proyectos</div>
          <img
            src="/index/paleta-de-pintura.gif"
            className="img-fluid d-md-none"
            alt="icono"
          ></img>
        </Link>

        <Link
          to="/#bio"
          onClick={() => props.handleScrollTo("#bio")}
          className={window.location.hash === "#bio" ? "active" : ""}
        >
          <div className="d-none d-md-block textBarra">Bio</div>
          <img
            src="/index/redes-sociales.gif"
            className="img-fluid d-md-none"
            alt="icono"
          ></img>
        </Link>

        <Link
          to="/#statement"
          onClick={() => props.handleScrollTo("#statement")}
          className={window.location.hash === "#statement" ? "active" : ""}
        >
          <div className="d-none d-md-block textBarra">Statement</div>
          <img
            src="/index/cuaderno.gif"
            className="img-fluid d-md-none"
            alt="icono"
          ></img>
        </Link>

        <Link
          to="/#contacto"
          onClick={() => props.handleScrollTo("#contacto")}
          className={window.location.hash === "#contacto" ? "active" : ""}
        >
          <div className="d-none d-md-block textBarra">Contacto</div>
          <img
            src="/index/mensaje.gif"
            className="img-fluid d-md-none"
            alt="icono"
          ></img>
        </Link>

        <Link
          to="/tienda"
          className={window.location.pathname === "/tienda" ? "active" : ""}
        >
          <div className="d-none d-md-block textBarra">Tienda</div>
          <img
            src="/index/tienda.gif"
            className="img-fluid d-md-none"
            alt="icono"
          ></img>
        </Link>
      </div>
    </nav>
  );
}

export default Nav;
