import React from "react";

const PNLagoPuelo = () => {
  const imagenStyle = {
    width: "25%",
    height: "auto",
  };

  const imagenStyle37 = {
    width: "37%",
    height: "auto",
  };

  const imagenStyle38 = {
    width: "38%",
    height: "auto",
  };

  const imagenStyle33 = {
    width: "33.33%",
    height: "auto",
  };

  const imagenStyle20 = {
    width: "20%",
    height: "auto",
  };

  const imagenStyle336 = {
    width: "33.6%",
    height: "auto",
  };

  const imagenStyle166 = {
    width: "16.6%",
    height: "auto",
  };

  const imagenStyle1428 = {
    width: "14.28%",
    height: "auto",
  };

  return (
    <>
      <section id="invernal">
        <h2>PN Lago Puelo</h2>

        <div className="container-fluid m-0 p-0 overflow-hidden">
          <div className="row d-flex p-0">
            <img
              className="col-12 col-sm-3 p-0 m-0 img-fluid"
              src="/PNLagoPuelo/pnLagoPuelo__1_-removebg-preview.png"
              alt="PN Lago Puelo"
              style={imagenStyle1428}
            />
            <img
              className="p-0 m-0 img-fluid"
              src="/PNLagoPuelo/pnLagoPuelo__2_-removebg-preview.png"
              alt="PN Lago Puelo"
              style={imagenStyle1428}
            />
            <img
              className="col-12 col-sm-3 p-0 m-0 img-fluid"
              src="/PNLagoPuelo/pnLagoPuelo__3_-removebg-preview.png"
              alt="PN Lago Puelo"
              style={imagenStyle1428}
            />
            <img
              className="col-12 col-sm-3 p-0 m-0 img-fluid"
              src="/PNLagoPuelo/pnLagoPuelo__4_-removebg-preview.png"
              alt="PN Lago Puelo"
              style={imagenStyle1428}
            />
            <img
              className="col-12 col-sm-3 p-0 m-0 img-fluid"
              src="/PNLagoPuelo/pnLagoPuelo__5_-removebg-preview.png"
              alt="PN Lago Puelo"
              style={imagenStyle1428}
            />
            <img
              className="col-12 col-sm-3 p-0 m-0 img-fluid"
              src="/PNLagoPuelo/pnLagoPuelo__6_-removebg-preview.png"
              alt="PN Lago Puelo"
              style={imagenStyle1428}
            />
            <img
              className="col-12 col-sm-3 p-0 m-0 img-fluid"
              src="/PNLagoPuelo/pnLagoPuelo__7_-removebg-preview.png"
              alt="PN Lago Puelo"
              style={imagenStyle1428}
            />
          </div>
          <p className="text-center pt-3 fs-5">
            Relato visual de mi experiencia como voluntaria en el Parque
            Nacional Lago Puelo durante febrero de 2023.
          </p>
          <div className="row d-flex m-0 p-0">
            <img
              className="col-12 col-sm-3 p-0 m-0 img-fluid"
              src="/PNLagoPuelo/pnLagoPuelo (32).jpg"
              alt="PN Lago Puelo"
              style={imagenStyle}
            />
            <img
              className="p-0 m-0 img-fluid"
              src="/PNLagoPuelo/pnLagoPuelo (33).jpg"
              alt="PN Lago Puelo"
              style={imagenStyle37}
            />
            <img
              className="col-12 col-sm-3 p-0 m-0 img-fluid"
              src="/PNLagoPuelo/pnLagoPuelo (31).jpg"
              alt="PN Lago Puelo"
              style={imagenStyle38}
            />
          </div>
        </div>

        <div className="row d-flex m-0 p-0">
          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (15).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle}
          />
          <img
            className="p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (23).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle37}
          />
          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (24).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle38}
          />
        </div>

        <div className="row d-flex m-0 p-0">
          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (22).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle}
          />
          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (10).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle}
          />
          <img
            className="p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (99).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle}
          />
          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (20).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle}
          />
        </div>

        <div className="row d-flex m-0 p-0">
          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (35).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle20}
          />
          <img
            className="p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (36).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle20}
          />

          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (16).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle20}
          />
          <img
            className="p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (17).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle20}
          />
          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (25).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle20}
          />
        </div>

        <div className="row d-flex m-0 p-0">
          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (27).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle33}
          />

          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (21).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle33}
          />
          <img
            className="p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (26).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle33}
          />
        </div>
        <div className="row d-flex m-0 p-0">
          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (34).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle33}
          />
          <img
            className="p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (18).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle33}
          />
          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (29).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle33}
          />
        </div>
        <div className="row d-flex m-0 p-0">
          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (9).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle336}
          />
          <img
            className="p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (11).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle166}
          />
          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (12).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle166}
          />
          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (13).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle166}
          />
          <img
            className="col-12 col-sm-3 p-0 m-0 img-fluid"
            src="/PNLagoPuelo/pnLagoPuelo (14).jpg"
            alt="PN Lago Puelo"
            style={imagenStyle166}
          />
        </div>
      </section>
    </>
  );
};

export default PNLagoPuelo;
