import React from "react";

const ReveladosOrganicos = () => {
  return (
    <>
      <section id="reveladosOrganicos">
        <h2>Revelar con plantas</h2>
        <div className="container-fluid overflow-hidden">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 pt-sm-2">
              <p className="text-center fs-4">
                Es un proyecto de investigación y experimentación de revelados
                con plantas y elementos de la naturaleza, reemplazando los
                tradicionales reveladores y fijadores industriales para
                fotografía blanco y negro. Este método puede ser utilizado tanto
                para negativos, generando una escala equilibrada de grises, como
                para papel donde las infusiones dejan su rastro de color.
                <br />
                <br />
                <a
                  href="https://reveladosorganicos.ar/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/reveladosOrganicos/webRevelados.png"
                    className="cajaCelu shadow p-3 mb-5 bg-body img-fluid"
                    alt="portada de instagram de revelados organicos"
                  />
                </a>
              </p>
            </div>
            <div className="d-none d-lg-block col-lg-1"></div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4">
              <figure className="figure">
                <img
                  src="/reveladosOrganicos/revelada_con_menta_I.jpg"
                  className="figure-img img-fluid d-block"
                  alt="Tres sillones de jardin de metal - blanco y negro"
                />
                <div className="revelados">
                  Cámara Zenit
                  <br />
                  Película ilford pan 100
                  <br />
                  Revelada con menta
                </div>
              </figure>
              <figure className="figure">
                <img
                  src="/reveladosOrganicos/revelada_con_oregano.jpg"
                  className="figure-img img-fluid d-block"
                  alt="treboles blanco y negro"
                />
                <div className="revelados">
                  Cámara Zenit
                  <br />
                  Película ilford pan 100
                  <br />
                  Revelada con orégano
                </div>
              </figure>
              <figure className="figure">
                <img
                  src="/reveladosOrganicos/revelada_con_menta_II.jpg"
                  className="figure-img img-fluid d-block"
                  alt="plantas blanco y negro"
                />
                <div className="revelados">
                  Cámara Zenit
                  <br />
                  Película ilford pan 100
                  <br />
                  Revelada con menta
                </div>
              </figure>

              <figure className="figure">
                <img
                  src="/reveladosOrganicos/revelada_con_te_negro_II.jpg"
                  className="d-block figure-img img-fluid"
                  alt="plantas blanco y negro"
                />

                <div className="revelados">
                  Cámara Zenit
                  <br />
                  Película ilford pan 100
                  <br />
                  Revelada con té negro
                </div>
              </figure>
              <figure className="figure">
                <img
                  src="/reveladosOrganicos/revelada_con_te_negro_III.jpg"
                  className="figure-img img-fluid d-block"
                  alt="maceta con flores blanco y negro"
                />

                <div className="revelados">
                  Cámara Zenit
                  <br />
                  Película ilford pan 100
                  <br />
                  Revelada con té negro
                </div>
              </figure>
              <figure className="figure">
                <img
                  src="/reveladosOrganicos/revelada_con_te_negro_IV.jpg"
                  className="figure-img img-fluid d-block"
                  alt="Flores y hojas grandes blanco y negro"
                />

                <div className="revelados">
                  Cámara Zenit
                  <br />
                  Película ilford pan 100
                  <br />
                  Revelada con té negro
                </div>
              </figure>

              <figure className="figure">
                <img
                  src="/reveladosOrganicos/rainy_day_caffenol.jpg"
                  className="figure-img img-fluid d-block"
                  alt="pies sobre un charcho de lluvia y el reflejo del fotografo blanco y negro"
                />

                <div className="revelados">
                  Cámara Zenit
                  <br />
                  Película ilford pan 100
                  <br />
                  Revelada con caffenol
                </div>
              </figure>
              <figure className="figure">
                <img
                  src="/reveladosOrganicos/revelada_con_te_negro_I.jpg"
                  className="figure-img img-fluid d-block centrarImagenes"
                  alt="plantas blanco y negro"
                />
                <div className="revelados">
                  Cámara Zenit
                  <br />
                  Película ilford pan 100
                  <br />
                  Revelada con té negro
                </div>
              </figure>
            </div>
          </div>
        </div>
      </section>{" "}
    </>
  );
};

export default ReveladosOrganicos;
